import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import "../../assets/scss/_modal.scss"
import {registerDomain} from "../../functions/registerDomain";
import {useNetwork} from "wagmi";
const customStyles = {
    content: {
        maxWidth: 600,
        width: '35%',
        maxHeight: '90vh',
        overflowY: 'auto',
        minWidth: 350,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '5px',
        marginRight: '-50%',
        border: '1px solid #1A1A1A',
        transform: 'translate(-50%, -50%)',
    },
};
export default function CreateSpaceIdModal({companyName, mainWalletAddress, show, close, isCompany=true }) {
    // const [price, setPrice] = useState("");
    const [spaceIdText, setSpaceIdText] = useState("");
    const [step, setStep] = useState(0);
    const [enableClose, setEnableClose] = useState(true);
    useEffect(() => {
        setSpaceIdText(companyName);
    }, [companyName])

    const { chain } = useNetwork()

    const spaceIdConfig = {
        arbitrum: [".arb", '0xA4B1', 42161],
        homestead: [".eth", '0x1', 1],
        bsc: [".bnb", '0x38', 56],
        default: ["", "", 0]
    };

    const [domain, clientIdx, clientId] = (chain && spaceIdConfig[chain.network]) || spaceIdConfig.default

    const handleRegisterDomain = async () => {
        setStep(1);
        setEnableClose(false);
        await registerDomain(spaceIdText.toLowerCase(), clientIdx, clientId, mainWalletAddress)
        // then:
            // set(true);
            // setEnableClose(true);
            // close();
            // setStep(0);
    }
    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^[a-zA-Z0-9]*$/.test(value)) {
            setSpaceIdText(value);
        }
    }

    return (
        <div>
            <Modal
                isOpen={show}
                onRequestClose={enableClose ? close : null}
                contentLabel="Example Modal"
                style={customStyles}
                shouldCloseOnOverlayClick={true}
            >
                    <div className='modal-content'>
                        {step === 0 &&
                            <div>
                                <div className='-title s'>Give a name for your {isCompany ? "project’s" : "profiles"} contract</div>
                                    <input
                                        type="text"
                                        name="documentation"
                                        pattern="[a-zA-Z0-9-]*"
                                        className="-input"
                                        value={spaceIdText}
                                        onChange={handleInputChange}
                                    />
                                    {/*<ul>*/}
                                        <div className="-text">Key points why register your {isCompany ? "project" : "profile"} name on the blockchain:</div>
                                        <li className="-list">Establish a unique identity within the decentralized space.</li>
                                        <li className="-list">Enhance visibility and recognition among potential investors and partners.</li>
                                        <li className="-list">Foster trust and transparency within the Forcefi community.
                                        </li>
                                        <li className="-list">Simplify transactions and interactions within the Forcefi platform.
                                        </li>
                                        <li className="-list">Please note that there are several name extensions such as .arb, .bnb, and .eth (It is suggested to use the one that your project is built on)</li>
                                    {/*</ul>*/}
                                {/*<div className='-text'>Price: {price}</div>*/}
                                <button className='-button' onClick={handleRegisterDomain}>Save</button>
                            </div>
                        }
                        {step === 1 &&
                            <div className='-loader'>
                                <div className="-text xl">Please wait... </div>
                                <div className="lds-dual-ring"></div>
                            </div>
                        }
                </div>
            </Modal>
        </div>
    );
}
