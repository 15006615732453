import React from 'react';

export default function TokenUntility(props) {
    return (
        <div className="token-utility">
            <div className="-card">
                <div className="-title">General Participation</div>
                <div className="-text">Stake 2,500 FORC to participate in token launches</div>
            </div>
            <div className="-card">
                <div className="-title">Dividend Earner</div>
                <div className="-text">Stake 150,000 FORC to earn dividends
                    30% of the fundraise fee* is distributed to distributed to dividend earners
                </div>
            </div>
            <div className="-card">
                <div className="-title">Fundraise fee*</div>
                <div className="-text">5% from total amount raised</div>
            </div>
            <div className="-card">
                <div className="-title">Curator Role</div>
                <div className="-text">Stake 50,000 FORC to become a curator.
                    50% of the fundraise fee* is distributed to Curators who are accepted as part of the Fundraise partner.</div>
            </div>
        </div>
    );
}