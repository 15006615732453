import React, {useEffect, useState} from 'react';
import Popover from '@mui/material/Popover';
import '../assets/scss/_notifications.scss';
import Icon from "../assets/icons/header/notifications-icon.svg"
import Telegram from "../assets/icons/curators/telegram.svg"
import axios from "axios";
import {useAccount} from "wagmi";
import '../assets/scss/_notifications.scss';
import NotificationsMessage from "./NotificationsMessage";
import {toast} from "react-toastify";

export default function Notifications() {
    const [anchorEl, setAnchorEl] = useState(null);
    const { address } = useAccount();
    const [notifications, setNotifications] = useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        getNotifications()
    },[anchorEl])

    const getNotifications = async () => {
        await axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/getReceivedNotifications', {params: {receiptAddress: address}})
            .then((response) => {
                const notificationsFromDb = [];
                response.data.forEach(async (notification) => {
                    notificationsFromDb.push(<NotificationsMessage notification={notification}/>)
                })
                setNotifications(notificationsFromDb)
            })
    }

    const handleUpdateNotification = async (action, senderAddress, receiptAddress, messageText) => {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/updateNotificationStatus',
            {senderAddress: senderAddress,
                    receiptAddress: receiptAddress,
                    status: action,
                    messageText: messageText})
            .then((response) => {
                toast.success("Match " + action)
                handleClose()
            })
    }

    return (
        <div>
            <div onClick={handleClick}>
                <img src={Icon} alt="" className="bell-icon"/>
            </div>
            <Popover
                id={id}
                disableScrollLock={true}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="notifications">
                    <div className="-header">
                        <div className='-title'>Notifications</div>
                    </div>
                    <div className="-navigation"></div>
                    <div>{notifications}</div>
                </div>
            </Popover>
        </div>
    );
}
