import { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";
import {useAccount} from "wagmi";

const CuratorsAccess = createContext();

export const useCuratorsAccess = () => {
    return useContext(CuratorsAccess);
};

export const CuratorsAccessProvider = ({ children }) => {
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const { address } = useAccount();
    const [hasCurratorAccess, setHasCurratorAccess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getUserAccessToCurators = async() => {
        axios.get(apiUrl + '/api/getDFoCompaniesCountByOwner', {params: {owner: address}})
            .then(response => {
                if (response.data > 0){
                    setHasCurratorAccess(true);
                    setIsLoading(false);
                } else {
                    axios.get(apiUrl + '/api/getUserProfile', {params: {walletAddress: address}})
                    .then(response => {
                        if (response.data.userRole === "Viewer" || response.data.isCurator){
                            setHasCurratorAccess(true);
                        } else {
                            setHasCurratorAccess(false);
                        }
                    });
                }
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getUserAccessToCurators();
    }, []);

    return (
        <CuratorsAccess.Provider value={{hasCurratorAccess , isLoading} } >
            {children}
        </CuratorsAccess.Provider>
    );
};
