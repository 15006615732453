import React, { useState, useEffect } from 'react';
import { Input, Select, Button, Divider, theme, Calendar } from "antd";
import getCurrentDate from "../../utils/getCurrentDate";
import { z } from 'zod';
import toSeconds from "../../constants/timeConstants";
import BeneficiarManager from "./BenificiarManager";

function CustomCalendar({ onDateSelect }) {
    const { token } = theme.useToken();
    const wrapperStyle = {
        width: '100%',
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };
    return (
        <div style={wrapperStyle}>
            <Calendar
                fullscreen={false}
                mode="month"
                onChange={value => onDateSelect(value.format('YYYY-MM-DD'))}
            />
        </div>
    );
}

export default function VestingConstructor({ formValues, setFormValues, setVestingError, singleVesting = false ,trigger}) {
    const [errors, setErrors] = useState([]);
    const [isBenificiarValid, setIsBenificiarValid] = useState(false);
    const [benificiarTrigger, setBenificiarTrigger] = useState(0);


    const beneficiarSchema = z.object({
        address: z.string().min(1, "Address is required"),
        amount: z.coerce.number().min(1, "Amount must be at least 1"),
    });

    const nonEmpty = z.union([
        z.string().nonempty("This field cannot be empty").refine(value => {
            const number = Number(value);
            return !isNaN(number) && number >= 0;
        }, "Must be a non-negative number"),
        z.number().nonnegative("Must be a non-negative number")
    ]);
    const vestingSchema = singleVesting ?
        z.object({
            vestingStart: z.string().min(1, "Vesting start is required"),
            vestingPeriod: nonEmpty,
            lockupPeriod: nonEmpty,
            releasePeriod: nonEmpty,
            tge: z.coerce.number().min(1, "TGE is required"),
        }):
        z.object({
            label: z.coerce.string().min(1, "Label is required"),
            vestingStart: z.string().min(1, "Vesting start is required"),
            vestingPeriod: nonEmpty,
            lockupPeriod: nonEmpty,
            releasePeriod: nonEmpty,
            tge: z.coerce.number().min(1, "TGE is required"),
            tokenAllocated: z.coerce.number().min(1, "Total Token Allocated is required"),
            beneficiars: z.array(beneficiarSchema),
    });

    useEffect(() => {
        if (trigger !== 0) {
            let allErrors = [];
            formValues.forEach((form, index) => {
                const validation = vestingSchema.safeParse(form);

                if ( formValues[index]?.beneficiars === [] || singleVesting) {
                    setVestingError(!validation.success)
                } else {
                    setVestingError(!validation.success || !isBenificiarValid)
                }
                if (!validation.success) {
                    allErrors[index] = validation.error.formErrors.fieldErrors;
                }
            });
            setErrors(allErrors);
        }
        setBenificiarTrigger(benificiarTrigger +1)
    }, [trigger]);

    const validateForm = (form) => {
        const validation = vestingSchema.safeParse(form);
        let isBeneficiarsValid = true;

        formValues.forEach(formValue => {
            if (formValue.beneficiars === []) {
                isBeneficiarsValid = false;
            }
        });

        if (isBeneficiarsValid && singleVesting) {
            setVestingError(!validation.success);
        } else {
            setVestingError(!validation.success || !isBeneficiarsValid);
        }

        if (!validation.success) {
            return validation.error.formErrors.fieldErrors;
        }

        return {};
    };

    const handleBeneficiarChange = (index, value) => {
        const newFormValues = [...formValues];
        newFormValues[index]['beneficiars'] = value;
        setFormValues(newFormValues);
        validateForm(formValues[index])
    }
    const handleInputChange = (index, key, value, subKey = null, nestedKey = null) => {
        const newFormValues = [...formValues];
        const newErrors = [...errors];
        const numericFields = ['vestingPeriod', 'lockupPeriod', 'releasePeriod', 'tge', 'tokenAllocated'];

        if (subKey !== null && nestedKey !== null) {
            newFormValues[index][key][subKey][nestedKey] = value;
        } else if (subKey !== null) {
            newFormValues[index][key][subKey] = value;
        } else {
            newFormValues[index][key] = numericFields.includes(key) ? (value === "" ? "" : parseFloat(value)) : value;
        }
        newErrors[index] = validateForm(newFormValues[index]);
        setFormValues(newFormValues);
        setErrors(newErrors);
    };

    const addForm = () => {
        setFormValues([...formValues, {
            label: "",
            vestingStart: getCurrentDate(),
            vestingPeriod: 0,
            vestingPeriodOption: "day",
            releasePeriod: 0,
            releasePeriodOption: "day",
            lockupPeriod: 0,
            lockupPeriodOption: "day",
            tge: 0,
            tokenAllocated: 0,
            beneficiars: [],
        }]);
        setErrors([...errors, {}]);
    };

    const removeForm = (index) => {
        if (formValues.length > 0) {
            const newFormValues = [...formValues];
            const newErrors = [...errors];
            newFormValues.splice(index, 1);
            newErrors.splice(index, 1);
            setFormValues(newFormValues);
            setErrors(newErrors);
        }
    };

    return (
        <>
            <div>
                <div className="-title m">
                    Manage the specifics of your vesting contracts
                </div>
                <div className="-text">
                    Add the details of each Unlock period by providing the necessary information below. The start of the first Unlock should occur at the end of the Cliff period.
                </div>
            </div>
            {formValues.map((form, index) =>
                <>
                    <div className="-add-plan" key={`form-${index}`}>
                        <div className="-container">
                            <div className="-title s">Unlock {index + 1}</div>
                            <div className="-text black">Choose a token from the available ones below:</div>
                        </div>
                        {index !== 0 && <Button onClick={() => removeForm(index)}>remove</Button>}
                    </div>
                    {!singleVesting &&
                        <div>
                            <div className="-text black offset">Provide token label</div>
                            <Input
                                placeholder=''
                                value={form.label}
                                onChange={e => handleInputChange(index, 'label', e.target.value)}
                                status={errors[index]?.label ? 'error' : ''}
                            />
                            {errors[index]?.label && <div className="-text error">{errors[index].label[0]}</div>}
                        </div>
                    }
                    <div>
                        <div className="-text black offset">Vesting start</div>
                        <CustomCalendar onDateSelect={date => handleInputChange(index, 'vestingStart', date)} />
                        {errors[index]?.vestingStart && <div className="error">{errors[index].vestingStart[0]}</div>}
                    </div>
                    <div className="-input-grid">
                        <div>
                            <div className="-text black offset">Vesting Period</div>
                            <Input
                                placeholder='Decimals'
                                type='number'
                                value={form.vestingPeriod}
                                onChange={e => handleInputChange(index, 'vestingPeriod', e.target.value)}
                                status={errors[index]?.vestingPeriod ? 'error' : ''}
                            />
                            {errors[index]?.vestingPeriod && <div className="-text error">{errors[index].vestingPeriod[0]}</div>}
                        </div>
                        <div>
                            <div className="-text black offset">Interval</div>
                            <Select
                                defaultValue={form.vestingPeriodOption}
                                className="-input"
                                onChange={value => handleInputChange(index, 'vestingPeriodOption', value)}
                                options={[
                                    { value: 'day', label: 'Day' },
                                    { value: 'week', label: 'Week' },
                                    { value: 'month', label: 'Month' },
                                    { value: 'quarter', label: 'Quarter' },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="-input-grid">
                        <div>
                            <div className="-text black offset">Lockup Period</div>
                            <Input
                                placeholder='Decimals'
                                type='number'
                                value={form.lockupPeriod}
                                onChange={e => handleInputChange(index, 'lockupPeriod', e.target.value)}
                                status={errors[index]?.lockupPeriod ? 'error' : ''}
                            />
                            {errors[index]?.lockupPeriod && <div className="-text error">{errors[index].lockupPeriod[0]}</div>}
                        </div>
                        <div>
                            <div className="-text black offset">Interval</div>
                            <Select
                                defaultValue={form.lockupPeriodOption}
                                className="-input"
                                onChange={value => handleInputChange(index, 'lockupPeriodOption', value)}
                                options={[
                                    { value: 'day', label: 'Day' },
                                    { value: 'week', label: 'Week' },
                                    { value: 'month', label: 'Month' },
                                    { value: 'quarter', label: 'Quarter' },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="-input-grid">
                        <div>
                            <div className="-text black offset">Release Period</div>
                            <Input
                                placeholder='Decimals'
                                type='number'
                                value={form.releasePeriod}
                                onChange={e => handleInputChange(index, 'releasePeriod', e.target.value)}
                                status={errors[index]?.releasePeriod ? 'error' : ''}
                            />
                            {errors[index]?.releasePeriod && <div className="-text error">{errors[index].releasePeriod[0]}</div>}
                        </div>
                        <div>
                            <div className="-text black offset">Interval</div>
                            <Select
                                defaultValue={form.releasePeriodOption}
                                className="-input"
                                onChange={value => handleInputChange(index, 'releasePeriodOption', value)}
                                options={[
                                    { value: 'day', label: 'Day' },
                                    { value: 'week', label: 'Week' },
                                    { value: 'month', label: 'Month' },
                                    { value: 'quarter', label: 'Quarter' },
                                ]}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="-text black offset">TGE %</div>
                        <Input
                            placeholder='0'
                            type='number'
                            value={form.tge}
                            onChange={e => handleInputChange(index, 'tge', e.target.value)}
                            status={errors[index]?.tge ? 'error' : ''}
                        />
                        {errors[index]?.tge && <div className="-text error">{errors[index].tge[0]}</div>}
                    </div>
                    {!singleVesting &&
                        <div>
                            <div className="-text black offset">Total Token Allocated</div>
                            <Input
                                type='number'
                                placeholder='0'
                                value={form.tokenAllocated}
                                onChange={e => handleInputChange(index, 'tokenAllocated', e.target.value)}
                                status={errors[index]?.tokenAllocated ? 'error' : ''}
                            />
                            {errors[index]?.tokenAllocated && <div className="-text error">{errors[index].tokenAllocated[0]}</div>}
                        </div>
                    }
                    {/*{!singleVesting &&*/}
                    {/*    <>*/}
                    {/*        {form.beneficiars.map((beneficiar, beneficiarIndex) => (*/}
                    {/*            <div key={beneficiarIndex}>*/}
                    {/*                <div className="-add-plan">*/}
                    {/*                    <div className="-container">*/}
                    {/*                        <div className="-title s">Beneficiar {beneficiarIndex + 1}</div>*/}
                    {/*                    </div>*/}
                    {/*                    <Button onClick={() => removeBeneficiar(index, beneficiarIndex)}>Remove</Button>*/}
                    {/*                </div>*/}
                    {/*                <div className="-input-grid">*/}
                    {/*                    <div>*/}
                    {/*                        <div className="-text black offset">Address</div>*/}
                    {/*                        <Input*/}
                    {/*                            placeholder='Address'*/}
                    {/*                            value={beneficiar.address}*/}
                    {/*                            onChange={e => handleInputChange(index, 'beneficiars', e.target.value, beneficiarIndex, 'address')}*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <div className="-text black offset">Amount</div>*/}
                    {/*                        <Input*/}
                    {/*                            placeholder='Amount'*/}
                    {/*                            type='number'*/}
                    {/*                            value={beneficiar.amount}*/}
                    {/*                            onChange={e => handleInputChange(index, 'beneficiars', e.target.value, beneficiarIndex, 'amount')}*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*        <div className="-add-plan">*/}
                    {/*            <div className="-container">*/}
                    {/*                <div className="-title s">Beneficiar {form.beneficiars.length + 1}</div>*/}
                    {/*                <div className="-text black">Add new Beneficiar</div>*/}
                    {/*            </div>*/}
                    {/*            <Button type="primary" onClick={() => addBeneficiar(index)}>Add beneficiar</Button>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*}*/}
                    { !singleVesting &&
                        <BeneficiarManager
                            beneficiars={form.beneficiars}
                            index={index}
                            handleBeneficiarChange={handleBeneficiarChange}
                            setIsBenificiarValid={setIsBenificiarValid}
                            validateForm={() => validateForm(formValues[index])}
                            benificiarTrigger={benificiarTrigger}
                        />
                    }
                    <Divider />
                </>
            )}
            {!singleVesting &&
                <div className="-add-plan">
                    <div className="-container">
                        <div className="-title s">Unlock {formValues.length + 1}</div>
                        <div className="-text black">Choose a token from the available ones below:</div>
                    </div>
                    <Button type="primary" onClick={addForm}>Add Plan</Button>
                </div>
            }
        </>
    );
}