import React from 'react'; // Import React if not already imported
import PolygonMumbai from "../assets/icons/header/polygonMumbai.svg"
import Binance from "../assets/icons/header/binance.svg"
import Ethereum from "../assets/icons/header/ethereum.svg"
import Optimism from "../assets/icons/header/optimism.svg";
import Arbitrum from "../assets/icons/arbitrum.svg"
import AllChains from "../assets/icons/AllChains.svg"
import Zksync from "../assets/icons/header/zksync.svg"


const ChainFilter = ({ queryChain, handleChangeChain, isProd }) => (
    <div className='chain-select'>
        <div className={queryChain === "AllChains" ? "-item active": "-item"} onClick={() => handleChangeChain("AllChains")}>
            <img src={AllChains} alt="" className="-img"/>
            All chains
        </div>
        {isProd ?
            <div className={queryChain === "arbitrum" ? "-item active": "-item"} onClick={() => handleChangeChain("arbitrum")}>
                <img src={Arbitrum} alt="" className="-img"/>
                Arbitrum
            </div>  :
            <div className={queryChain === "arbitrum-goerli" ? "-item active": "-item"} onClick={() => handleChangeChain("arbitrum-goerli")}>
                <img src={Arbitrum} alt="" className="-img"/>
                Arbitrum
            </div>
        }
        {isProd ?
            <div className={queryChain === "homestead" ? "-item active": "-item"} onClick={() => handleChangeChain("homestead")}>
                <img src={Ethereum} alt="" className="-img"/>
                Ethereum
            </div>
            :
            <div className={queryChain === "goerli" ? "-item active": "-item"} onClick={() => handleChangeChain("goerli")}>
                <img src={Ethereum} alt="" className="-img"/>
                Ethereum
            </div>
        }
        <div className={queryChain === "bsc" ? "-item active": "-item"} onClick={() => handleChangeChain("bsc")}>
            <img src={Binance} alt="" className="-img"/>
            Binance
        </div>
        <div className={queryChain === "matic" ? "-item active": "-item"} onClick={() => handleChangeChain("matic")}>
            <img src={PolygonMumbai} alt="" className="-img"/>
            Polygon
        </div>
        {!isProd ?
            <div className={queryChain === "optimism-goerli" ? "-item active": "-item"} onClick={() => handleChangeChain("optimism-goerli")}>
                <img src={Optimism} alt="" className="-img"/>
                Optimism
            </div>
            :
            <div className={queryChain === "optimism" ? "-item active": "-item"} onClick={() => handleChangeChain("optimism")}>
                <img src={Optimism} alt="" className="-img"/>
                Optimism
            </div>
        }
        <div className={queryChain === "zksync" ? "-item active": "-item"} onClick={() => handleChangeChain("zksync")}>
            <img src={Zksync} alt="" className="-img"/>
            Zksync
        </div>
    </div>
);

export default ChainFilter;
