import * as React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InfoBtn from '../../assets/icons/info-btn.svg'
export default function InformationButton({text}) {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} placement="right-start" title={text}>
            <img src={InfoBtn} alt="" style={{marginLeft: '8px'}}/>
        </Tooltip>
    );
}