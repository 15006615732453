import React, {useEffect, useState} from 'react';
import {Button, Input, Table} from 'antd';
const InvestmentTable = ({initialData, handleReclaimTokens}) => {
    const filteredData = initialData.filter(item => parseFloat(item.balanceEth) > 0);
    const columns = [
        {
            title: 'Token address',
            dataIndex: 'investmentTokenAddress',
            key: 'investmentTokenAddress',
        },
        {
            title: 'Token name',
            dataIndex: 'tokenName',
            key: 'tokenName',
        },
        {
            title: 'Token ticker',
            dataIndex: 'tokenTicker',
            key: 'tokenTicker',
        },
        {
            title: 'Balance',
            dataIndex: 'balanceEth',
            key: 'balanceEth',
        },
        {
            title: '',
            key: 'actionButton',
            render: (text, record) => (
                <div>
                    {record.actionButton &&
                            <Button
                                style={{ width: '90px' }}
                                type='default'
                                onClick={() => handleReclaimTokens(record.investmentTokenAddress)}
                            >
                                Reclaim
                            </Button>
                    }
                </div>
            ),
        },
    ];

    return (
        <Table
            dataSource={filteredData}
            columns={columns}
            pagination={false}
            rowKey={(record, index) => index}
        />

    );
};

export default InvestmentTable;
