import React, {useState} from 'react';
import "../assets/scss/_company-card.scss"
import Ethereum from "../assets/icons/header/ethereum.svg";
import Binance from "../assets/icons/header/binance.svg";
import PolygonMumbai from "../assets/icons/header/polygonMumbai.svg";
import Arbitrum from "../assets/icons/arbitrum.svg";
import {useNavigate} from "react-router-dom";
import Optimism from "../assets/icons/header/optimism.svg";
import Zksync from "../assets/icons/header/zksync.svg"
import axios from "axios";
import Modal from "react-modal";
import "../assets/scss/_modal.scss"
import "../assets/css/spinner.css";
import {useCuratorsAccess} from "../contexts/CuratorsAccess";
import {Tag} from "antd";

const cardsChainsText = {
    "homestead": "Ethereum",
    "goerli": "Ethereum",
    "bsc": "Binance",
    "matic": "Polygon",
    "arbitrum": "Arbitrum",
    "arbitrum-goerli" : "Arbitrum",
    "optimism-goerli": "Optimism",
    "optimism": "Optimism",
    "zksync": "Zksync",
};

const cardsChainsIcon = {
    "homestead": Ethereum,
    "goerli": Ethereum,
    "bsc" : Binance,
    "matic" : PolygonMumbai,
    "arbitrum" : Arbitrum,
    "arbitrum-goerli" : Arbitrum,
    "optimism-goerli": Optimism,
    "optimism": Optimism,
    "zksync": Zksync,
};

function CompanyCuratorsModal(props) {
    const customStyles = {
        content: {
            maxWidth: 500,
            width: '35%',
            maxHeight: '60vh',
            overflowY: 'auto',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: '4px',
            marginRight: '-50%',
            border: '1px solid #000',
            transform: 'translate(-50%, -50%)',
        },
    };
    return (
        <div>
            <Modal
                isOpen={props.show}
                onRequestClose={props.close}
                style={customStyles}
                contentLabel="Curator Matching Modal"
            >
                <div className='modal-content small-margin'>
                    {props.curatorsList.length === 0 && <div>Company has no curators</div>}
                    <div>{props.curatorsList}</div>
                </div>
            </Modal>
        </div>
    );
}


export default function CompanyCard({item, key, enabledLink = true, curatorButton =true}) {
    const [linkIsHovered, setLinkIsHovered] = useState(false);
    const navigate = useNavigate();
    const convertDateTime = (originalDate) => new Date(originalDate).toLocaleDateString('en-GB');
    const handleOpenDfo = (wallet, activated, network) => {
        navigate('/dfo-page?id=' + wallet + "&&chain=" + network);
    };
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [curatorsList, setCuratorsList] = useState(<div className='lds-dual-ring'></div>);
    const handleGetCurators = async () => {
        setCompanyCuratorsModal(true);
        try {
            await axios.get(apiUrl + '/api/getMatchedCurators', {params : {projectAddress : item.mainWalletAddress}})
                .then(response => {
                    setCuratorsList(response.data.map((item, key) =>
                        <div className='company-curators' key={key}>
                            <img className='-img' src={item.avatarLogo} alt=""/>
                            <div className='-text'>{item.nickName}</div>
                        </div>))
                })
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle 401 Unauthorized error here
                setCuratorsList(<div>Unauthorized - This wallet doesn't have access</div>)
            } else {
                // Handle other errors
                setCuratorsList(<div>An error occurred: {error.response}</div>)
            }
        }


    }
    // const [isConnected, setIsConnected] = useState(false);
    const [companyCuratorsModal, setCompanyCuratorsModal] = useState(false);
    const {hasCurratorAccess} = useCuratorsAccess();
    return (
        <div className='company-card' key={key}
             onClick={() => !companyCuratorsModal && enabledLink && !linkIsHovered && handleOpenDfo(item.companyName, item.activated, item.network)}>
            <div className="-header-container">
                <div className="-main-info">
                    {item.logo === "" ?
                        <div className="-img"></div>
                        :
                        <img src={item.logo} className='-img' alt=""/>
                    }
                    <div className='-text xl'>{item.companyName}</div>
                </div>
                {cardsChainsIcon[item.network] &&
                    <div className='-text xs center'>
                        <img src={cardsChainsIcon[item.network]} className='-img chain' alt=""/>{cardsChainsText[item.network]}
                    </div>
                }
            </div>
            <div className='-text elevator'>{item.elevatorPitch}</div>
            <div>
                <Tag color="blue" >{item.listingTier}</Tag>
                <Tag color="geekblue" >{item.projectStatus}</Tag>
                {/*<Tag >{convertDateTime(item.initializedTimestamp)}</Tag>*/}
            </div>
            <div>
                {curatorButton && hasCurratorAccess &&
                    <Tag className='-role'
                         onMouseEnter={() => setLinkIsHovered(true)}
                         onMouseLeave={() => setLinkIsHovered(false)}
                         onClick={handleGetCurators}
                    >Curators</Tag>
                }
                {item.websiteUrl !== "" && item.websiteUrl !== undefined &&
                    <a href={item.websiteUrl} target='_blank'>
                        <Tag
                           onMouseEnter={() => setLinkIsHovered(true)}
                           onMouseLeave={() => setLinkIsHovered(false)}
                           >Website
                        </Tag>
                    </a>
                }
                {item.documentation !== "" && item.documentation !== undefined &&
                    <a href={item.documentation} target='_blank'>
                        <Tag
                           onMouseEnter={() => setLinkIsHovered(true)}
                           onMouseLeave={() => setLinkIsHovered(false)}
                           target='_blank'>
                            Documentation
                        </Tag>
                    </a>

                }
            </div>
            <CompanyCuratorsModal show={companyCuratorsModal} close={() => setCompanyCuratorsModal(false)} curatorsList={curatorsList}/>
        </div>
    );
}
