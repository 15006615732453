const erc20InvestmentTokens = {
    // SEPOLIA
    11155111: {
        USDT: "0xC09291B33A6E7ba4D7c293D58a6683C1f9F2946C",
        USDC: "0x3a9725495A7552a4de196962784ba073f0ac89fc",
        DAI: "0xdf3477E7a8C21dAa0AB5B8089466FfFAD53d8B3b",

        FUNDRAISING_ADDRESS: "0x4e809Ab87e7539ABc4e8abCE8D467F72F4Ae3C64",
        VESTING_ADDRESS: "0xE34eEeEe5224c54860B006c4d8372AbE833105bb",
        PACKAGE_ADDRESS: "0x6b0A28D26F5e992409287B16A9232E9ade9E769B",

        SIMPLE_FACTORY_ADDRESS: "0x285bf9785B98D72af2DB12Cfc14D744093b70C51",
        PAUSABLE_FACTORY_ADDRESS: "0x217d52483A2f118753F2f557Ed4f91236A635A5d"
    },

    // ARBITRUM-SEPOLIA
    421614: {
        USDT: "0xe52DE84a84ddB269Ef3b9b75471cd925F0DEdC88",
        USDC: "0x6233498849c1d5c6E07d69c375Bc81F174fA8D8F",
        DAI: "0xCbeA3Afc3a8718c8b9b9c2b05C44841f5fF02daE",

        FUNDRAISING_ADDRESS: "0x5cAA60b315817786F1D460b45659F79353FE856C",
        VESTING_ADDRESS: "0x9c4821c335dDc2b6AD3304Ec6a87aA448D9641Bf",
        PACKAGE_ADDRESS: "0xf6f895b17744c4F30C146Db30Cd3c25C8bbF5837",

        SIMPLE_FACTORY_ADDRESS: "0x8f096364Ef74e7f5b0Aaf47ad8DBf4286eC07BC4",
        PAUSABLE_FACTORY_ADDRESS: "0x58c74F7259C45816B0639D776EC7dC2D2583E3E6"
    },

    // LINEA-SEPOLIA
    59141: {
        USDT: "0xf6f895b17744c4F30C146Db30Cd3c25C8bbF5837",
        USDC: "0x5cAA60b315817786F1D460b45659F79353FE856C",
        DAI: "0x9c4821c335dDc2b6AD3304Ec6a87aA448D9641Bf",

        FUNDRAISING_ADDRESS: "0x6233498849c1d5c6E07d69c375Bc81F174fA8D8F",
        VESTING_ADDRESS: "0xe52DE84a84ddB269Ef3b9b75471cd925F0DEdC88",
        PACKAGE_ADDRESS: "0xCbeA3Afc3a8718c8b9b9c2b05C44841f5fF02daE",

        SIMPLE_FACTORY_ADDRESS: "0x8f096364Ef74e7f5b0Aaf47ad8DBf4286eC07BC4",
        PAUSABLE_FACTORY_ADDRESS: "0xc61503eC105aE2f972F9fdb3c5a6CD7b3c234212"
    },

};

export default erc20InvestmentTokens;
