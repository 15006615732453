import React from 'react';
import "../../assets/scss/_home.scss"
import distribution from "../../assets/images/home/distribution.svg";


export default function Distribution(props) {
    const data = [
        { title: "Seed Sale  5.50%", description: "10% TGE, 3 months cliff, 12 months vesting", accent: "#B8B8B8" },
        { title: "Presale  3%", description: "25% TGE, 0 mo cliff, 6 mo vesting", accent: "#CA510D" },
        { title: "Public Sale 5%", description: "100% TGE", accent: "#CAB2D6" },
        { title: "Public sale bonus program 2.50%", description: "1 mo vesting", accent: "#1677FF" },
        { title: "Team  10%", description: "18 mo cliff, 24 mo vesting", accent: "#33A02C" },
        { title: "Advisors  5%", description: "18 mo cliff, 24 mo vesting", accent: "#6A3D9A" },
        { title: "Marketing  7%", description: "24 mo vesting. 3 mo lock-up", accent: "#FDBF6F" },
        { title: "Partnerships  10%", description: "12 mo cliff, 36 mo vesting", accent: "#FB9A99" },
        { title: "Curators Program  12%", description: "48 mo distribution, 3 mo lock-up", accent: "#1F78B4" },
        { title: "Project Fund  12%", description: "48 mo distribution, 3 mo lock-up", accent: "#B2DF8A" },
        { title: "Airdrops  5%", description: "36 mo linear vesting", accent: "#FFFF99" },
        { title: "Exchange Liquidity  10%", description: "25% TGE, 3 months vesting", accent: "#D9D9D9" },
        { title: "Reserves  13%", description: "1% TGE, 60 mo Vesting", accent: "#A6CEE3" },

    ];

    return (
        <div className="distribution">
            <div className="-left">
                <img className="-img" src={distribution} alt=""/>
                <div className="-table">
                        <div className="-cell header">Info</div>
                        <div className="-cell header">Value</div>
                        <div className="-cell">Token Ticker</div>
                        <div className="-cell">FORC</div>
                        <div className="-cell accent">Total Supply</div>
                        <div className="-cell accent">1,000,000,000 FORC</div>
                        <div className="-cell">Blockchain</div>
                        <div className="-cell">Ethereum (ERC-20)</div>
                        <div className="-cell accent">Decimals</div>
                        <div className="-cell accent">18</div>
                </div>
            </div>
            <div className="-cards">
                {data.map((item, index) => (
                    <div key={index} className="-card">
                        <div className="-accent" style={{ background: item.accent }}></div>
                        <div>
                            <div className="-title">{item.title}</div>
                            <div className="-text">{item.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}