import React from 'react';
import "../../assets/scss/_token-creation.scss"

export default function ChooseToken({setTokenAddress,existingToken, setExistingToken, setFundraisingStep}) {
    return (
        <div className='token-creation'>
            <div>
                <div className='-title'>Create fundraising</div>
                <div className="-select">
                    <div className={existingToken ? "-tab active" : "-tab"} onClick={() => setExistingToken(true)}>
                        Create with existing token
                    </div>
                    <div className={!existingToken ? "-tab active" : "-tab"} onClick={() => setExistingToken(false)}>
                        Create new token
                    </div>
                </div>
                { existingToken &&
                    <>
                        <div className="-text">Token address</div>
                        <input
                            type="text"
                            name="tokenAddress"
                            className="-input"
                            placeholder="address"
                            onChange={e => setTokenAddress(e.target.value)}
                        />
                    </>
                }
                <button
                    type="submit"
                    className="-button mini"
                    onClick={() => setFundraisingStep(1)}
                >
                    Next step
                </button>
            </div>
        </div>
    );
}