import React, {useEffect, useState} from 'react';
import {useSignMessage} from "wagmi";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import * as ethers from "ethers";

export const useAuthenticate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const EXPIRATION_DATE_TOKEN_IN_SECONDS = 2592000000
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const[message, setMessage] = useState("")
    const[token, setToken] = useState("")

    const [signEnabled, setSignEnabled] = useState(false);

    const authenticate = async(address) => {
        await axios.get(apiUrl + '/nonce' + "?address=" + address)
            .then(response => {
                // setMessage(response.data.message)
                // setToken(response.data.tempToken)
                signMessageEthers(response.data.message, response.data.tempToken)
            })
    }


    let provider;

    if (window.ethereum) {
        provider = new ethers.BrowserProvider(window.ethereum);
    } else {
        console.error("No browser or Ethereum provider available.");
    }

    const signMessageEthers = async(signatureMessage, signatureToken) => {
        const signer = await provider.getSigner();
        let signature = await signer.signMessage( signatureMessage );
        await authenticateOnServer(signature, signatureToken)
    }

    // useEffect(() => {
    //     setSignatureMsg(data)
    // }, [data])
    const[signatureMsg, setSignatureMsg] = useState("")
    // useEffect(async() => {
    //     await signMessageEthers()
    // }, [signEnabled])

    // useEffect(() => {
    //     console.log("I Should be here1")
    //     if (signatureMsg !== undefined && signatureMsg !== ""){
    //         console.log("I Should be here2")
    //         authenticateOnServer()
    //     }
    // }, [signatureMsg])

    const authenticateOnServer = async(signature, signatureToken) => {
        let opts = {
            method: 'POST',
            // body: JSON.stringify({"referredBy": localStorage.getItem("referral").replace(/"/g, '')}),
            body: JSON.stringify({"referredBy": ""}),
            headers: {
                'Content-Type': "application/json",
                "Authorization": `Bearer ${signatureToken}`
            }
        }
        let res = await fetch(`/verify?signature=${signature}`, opts)
        let resBody = await res.json();

        localStorage.setItem('authToken', "Bearer " + resBody.token);
        const expirationDate = Date.now() + EXPIRATION_DATE_TOKEN_IN_SECONDS
        localStorage.setItem('expirationDate', expirationDate.toString());

        if(location.pathname === "/curators"){
            navigate('/curators')
        }
    }

    return {
        authenticate,
        authenticateOnServer,
        signatureMsg,
        setSignatureMsg,
    };
}
