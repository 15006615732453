import React from 'react';
import Modal from 'react-modal';
import "../../assets/scss/_modal.scss"
import axios from "axios";
import {toast} from "react-toastify";

const customStyles = {
    content: {
        width: '35%',
        minWidth: 300,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '20px',
        marginRight: '-50%',
        border: '1px solid #fff',
        transform: 'translate(-50%, -50%)',
    },
};

export default function EarlyAccessModal(props) {

    const handleSubmitEmail = async(e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        if (isEmail(data.get('email')) === false){
            toast.error('Invalid email');
            return;
        }
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/saveEmail', {
            email: data.get('email')
        }).then(props.close());
    }

    function isEmail(val) {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test(val)){
            return false;
        }
    }

    return (
        <div>
            <Modal
                isOpen={props.show}
                onRequestClose={props.close}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <div className='modal-content'>
                    <div className='-title'>Get early access</div>
                    <form onSubmit={handleSubmitEmail}>
                        <div className='-text xl'>Enter your email address</div>
                        <input
                            type="text"
                            name="email"
                            className="-input xl"
                            placeholder="email address"
                        />
                        <button
                            className="-button xl"
                            type="submit"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </Modal>
        </div>
    );
}
