import React from 'react';
import InformationButton from "../elements/InformationButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import currentDateTime from "../../utils/currentDateTime";

export default function FundraisingConstructor({fundraisingFormValues,
                                                   handleChangeFundraising,
                                                   setFundraisingFormValues,
                                                   setFundraisingCount,
                                                   setEncodedFundraisingFormValues,
                                                   fundraisingCount}) {
    let actualDateTime = currentDateTime();

    let addFundraisingFormFields = () => {
        setFundraisingFormValues([...fundraisingFormValues, {
            label : "",
            vestingStart : new Date(),
            cliffPeriod: 0,
            cliffPeriodOption: "day",
            vestingPeriod: 0,
            vestingPeriodOption: "day",
            releasePeriod: 0,
            releasePeriodOption: "day",
            tgePercent: 0,
            minTicketLimit: 0,
            totalCampaignLimit: 0,
            campaignSoftCap: 0,
            rate: 1,
            saleStartDate: new Date(),
            saleEndDate: new Date(),
            isPrivate: false }])

        const amnt = fundraisingCount + 1;
        setFundraisingCount(amnt);
    }
    let removeFundraisingFormFields = (i) => {
        let newFormValues = [...fundraisingFormValues];
        newFormValues.splice(i, 1);
        setFundraisingFormValues(newFormValues);

        let newEncodedFormValues = [...fundraisingFormValues];
        newEncodedFormValues.splice(i, 1);
        setEncodedFundraisingFormValues(newEncodedFormValues);

        const amnt = fundraisingCount - 1;
        setFundraisingCount(amnt);
    }

    return (
        <>
            <form>
                {fundraisingFormValues.map((element, index) => (
                    <div key={index}>
                        <div className='-text plan'>Fundraising parameters</div>
                        <input
                            type="text"
                            placeholder="Label"
                            name="label"
                            className="-input short"
                            value={element.label}
                            onChange={e => handleChangeFundraising(index, e)}
                        />
                        <div className='-text'>Vesting start<InformationButton text='When vesting will start'/></div>
                        <input
                            type="datetime-local"
                            name="vestingStart"
                            className="-input short"
                            min={actualDateTime}
                            value={element.vestingStart}
                            onChange={e => handleChangeFundraising(index, e)}
                        />
                        <div className='-text'>Cliff period <InformationButton text='For how much time tokens will be locked'/></div>
                        <div className='-input-grid'>
                            <input
                                type="number"
                                min="1"
                                max="31"
                                name="cliffPeriod"
                                className="-input short"
                                placeholder=""
                                onChange={e => handleChangeFundraising(index, e)}
                                value={element.cliffPeriod}
                            />
                            <select className='-input select' name="cliffPeriodOption" onChange={e => handleChangeFundraising(index, e)} value={element.cliffPeriodOption}>
                                <option value="day">day</option>
                                <option value="week">week</option>
                                <option value="month">month</option>
                                <option value="quarter">quarter</option>
                            </select>
                        </div>

                        <div className='-text'>Vesting period <InformationButton text='How long will vesting last'/></div>
                        <div className='-input-grid'>
                            <input
                                type="number"
                                min="1"
                                max="31"
                                name="vestingPeriod"
                                className="-input short"
                                placeholder=""
                                onChange={e => handleChangeFundraising(index, e)}
                                value={element.vestingPeriod}
                            />
                            <select className='-input select' name="vestingPeriodOption" onChange={e => handleChangeFundraising(index, e)} value={element.vestingPeriodOption}>
                                <option value="day">day</option>
                                <option value="week">week</option>
                                <option value="month">month</option>
                                <option value="quarter">quarter</option>
                            </select>
                        </div>
                        <div className='-text'>Release period <InformationButton text='How often in vesting period tokens will be available for claim. If period is 0, tokens are distributed linearly'/></div>
                        <div className='-input-grid'>
                            <input
                                type="number"
                                min="1"
                                max="31"
                                name="releasePeriod"
                                className="-input short"
                                placeholder=""
                                onChange={e => handleChangeFundraising(index, e)}
                                value={element.releasePeriod}
                            />
                            <select className='-input select' name="releasePeriodOption" onChange={e => handleChangeFundraising(index, e)} value={element.releasePeriodOption}>
                                <option value="day">day</option>
                                <option value="week">week</option>
                                <option value="month">month</option>
                                <option value="quarter">quarter</option>
                            </select>
                        </div>
                        <div className="-input-grid">
                            <div>
                                <div className='-text'>TGE % <InformationButton text='How much tokens will be available right away to claim when vesting starts'/></div>
                                <input className='-input short'
                                       type="number"
                                       value={element.tgePercent}
                                       data-mask='0%'
                                       placeholder='percent'
                                       name="tgePercent"
                                       onChange={e => handleChangeFundraising(index, e)}
                                />
                            </div>
                        </div>
                        <div className="-input-grid">
                            <div>
                                <div className='-text'>Min amount <InformationButton text='Minimal amount that is required to invest in fundraise'/></div>
                                <input className='-input short'
                                       type="number"
                                       value={element.minTicketLimit}
                                       data-mask='0%'
                                       placeholder='Min Ticket'
                                       name="minTicketLimit"
                                       onChange={e => handleChangeFundraising(index, e)}
                                />
                            </div>
                        </div>

                        <div className="-input-grid">
                            <div>
                                <div className='-text'>Campaign limit <InformationButton text='Campaign limit equals on what is the token maximal amount that can be fundraised'/></div>
                                <input className='-input short'
                                       type="number"
                                       value={element.totalCampaignLimit}
                                       name="totalCampaignLimit"
                                       onChange={e => handleChangeFundraising(index, e)}
                                />
                            </div>
                        </div>
                        <div className="-input-grid">
                            <div>
                                <div className='-text'>Campaign soft cap <InformationButton text='Soft cap equals on what is the token minimal amount that should be fundraised to successfully close campaign'/></div>
                                <input className='-input short'
                                       type="number"
                                       value={element.campaignSoftCap}
                                       name="campaignSoftCap"
                                       onChange={e => handleChangeFundraising(index, e)}
                                />
                            </div>
                        </div>
                        {/*<div className='-text'>Rate <InformationButton text='Rate is...'/></div>*/}
                        {/*<input className='-input short'*/}
                        {/*       type="number"*/}
                        {/*       value={element.rate}*/}
                        {/*       name="rate"*/}
                        {/*       onChange={e => handleChangeFundraising(index, e)}*/}
                        {/*/>*/}
                        <div className="-input-grid">
                            <div>
                                <div className='-text'>Sale start</div>
                                <input
                                    type="datetime-local"
                                    name="saleStartDate"
                                    className="-input short"
                                    min={actualDateTime}
                                    value={element.saleStartDate}
                                    onChange={e => handleChangeFundraising(index, e)}
                                />
                            </div>

                            <div>
                                <div className='-text'>Sale ends</div>
                                <input
                                    type="datetime-local"
                                    name="saleEndDate"
                                    className="-input short"
                                    value={element.saleEndDate}
                                    onChange={e => handleChangeFundraising(index, e)}
                                />
                            </div>
                        </div>
                        {/*<div className='-text'>Private fundraising</div>*/}
                        {/*<input*/}
                        {/*    type="checkbox"*/}
                        {/*    name="isPrivate"*/}
                        {/*    className="-checkbox"*/}
                        {/*    value={element.isPrivate}*/}
                        {/*    onChange={e => handleChangeFundraising(index, e)}*/}
                        {/*/>*/}
                        <FormControlLabel control={<Checkbox />} label="Private fundraising" value={element.isPrivate} onChange={e => handleChangeFundraising(index, e)} />


                        {
                            index ?
                                <div className='-right-block'>
                                    <button type="button"  className="-add-input remove" onClick={() => removeFundraisingFormFields(index)}>Remove fundraising</button>
                                </div>
                                : null
                        }
                    </div>
                ))}
                <div className="-right-block">
                    <button className="-add-input" type="button" onClick={() => addFundraisingFormFields()}>Additional fundraising</button>
                </div>
            </form>
            {/*<button*/}
            {/*    type="submit"*/}
            {/*    className="-button"*/}
            {/*    onClick={handleDeployToken}*/}
            {/*>*/}
            {/*    Next*/}
            {/*</button>*/}
        </>

    );
}