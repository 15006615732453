import React, {useEffect, useState} from "react";
import '../assets/scss/_header.scss';
import '../assets/scss/_components.scss';
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import Logo from "../assets/images/forcefi-logo.svg";
import {Avatar} from 'antd';
import axios from "axios";
import {useAccount, useConnect, useNetwork, useSignMessage} from 'wagmi'
import Burger from "./Burger";
import LogoWhite from "../assets/images/home/logo-white.svg"

import Notifications from "./Notifications";
import {getLocal} from "web3modal";
import {useAuthenticate} from "../hooks/useAuthenticate";
import ConnectButton from "../utils/ConnectButton";
import ChainSelector from "./ChainSelector";

export default function Header() {

    const location = useLocation();
    const { address } = useAccount();
    const { chain } = useNetwork()
    const navigate = useNavigate();
    const { isConnected } = useAccount()

    const [userAvatar, setUserAvatar] = useState(false);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const getUserAvatar = async () => {
        await axios.get(apiUrl + '/api/getUserProfileLogo', {params: {walletAddress: address}})
            .then(response => {
                setUserAvatar(response.data.avatarLogo)
            })
    }

    const[logoLink] = useState("/");
    useEffect(() => {
        getUserAvatar();
    }, [])

    const { authenticate } = useAuthenticate();

    const account = useAccount({
        onConnect({ address, connector, isReconnected }) {
            console.log('Connected', { address, connector, isReconnected })
            // if ((Number(localStorage.getItem('expirationDate')) - new Date() < 0)) {
            //     authenticate(address);
            // }
            if (!isReconnected){
                if (getLocal("homePageNavigation") === true){
                    navigate('/registration')
                } else {
                    navigate('/cabinet')
                }
                window.location.reload()
            }
        },
        onDisconnect() {
            localStorage.removeItem('authToken');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('homePageNavigation');
            console.log('Disconnected')
            navigate('/');
            window.location.reload()
        },
    })

    return (
        <>
        <div className={location.pathname === '/' ? 'navbar home' : 'navbar'}>
            <NavLink to={logoLink} className='-index'>
                {location.pathname === '/' ?
                    <img src={LogoWhite} alt='' className='-logo home' />:
                    <img src={Logo} alt='' className='-logo' />
                }
            </NavLink>
            {(location.pathname !== '/') &&
            <div className='-nav-wrapper'>
                <div className='-nav-items left'>

                </div>
                <div className='-nav-items right'>

                    <div className='-desktop'>
                        <Link className='-item' to='/registration'>List project</Link>
                        <Link className='-item' to='/projects'>Projects</Link>
                        <Link className='-item' to='/curators'>Curators</Link>
                        <Link className='-item' to='/partners'>Partners</Link>
                    </div>
                    {isConnected &&
                        <>
                            <div className='-desktop'>
                                <Link to='/cabinet'><Avatar src={userAvatar}/></Link>
                            </div>
                            <Notifications />
                        </>
                    }
                    {chain && <ChainSelector/>}
                    <div className='-desktop'>
                        <ConnectButton buttonType={"web3Button"} homePageNavigation={false} buttonName={address}/>
                    </div>
                    <Burger />
                </div>
            </div>
            }
            {(location.pathname === '/') &&
                <div className='-nav-wrapper'>
                    <div className='-nav-items left'/>
                    <div className='-nav-items right'>
                        <div className="-desktop"><Link className='-item home' to='/projects'>Projects</Link></div>
                        <div className="-desktop"><Link className='-item home' to='/curators'>Curators</Link></div>
                        <div className="-desktop"><Link className='-item home' to='/partners'>Partners</Link></div>
                        <div className='-desktop'>

                            {isConnected ?
                                <Link to='/cabinet'><Avatar src={userAvatar}/></Link>
                                    :
                                <ConnectButton buttonType={"app-button"} homePageNavigation={false} buttonName={"Get Started"}/>
                            }
                        </div>
                        <div className='-desktop'>
                            {isConnected &&
                                <ConnectButton buttonType={"web3Button"} homePageNavigation={false} buttonName={address}/>
                            }
                        </div>
                        <Burger />
                    </div>
                </div>
            }
        </div>
        </>
    );
}
