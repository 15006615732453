import React from 'react';
import Modal from 'react-modal';
import Spinner from '../../components/Spinner';
import "../../assets/scss/_modal.scss"


const customStyles = {
    content: {
        width: 600,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0px',
        marginRight: '-50%',
        border: '2px solid #000',
        transform: 'translate(-50%, -50%)',
    },
};

export default function LoadingModal(props) {

    return (
        <div>
            <Modal
                isOpen={props.show}
                // onRequestClose={props.close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='modal-content'>
                 <Spinner text={props.text}/>
                </div>
            </Modal>
        </div>
    );
}
