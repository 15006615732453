import axios from "axios";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function sendRegistrationNotification (projectName, website, description, achievmentsReq, challenges, supportType, doxxedProfiles, socialChannels, contactPerson) {
    await axios.post(apiUrl + '/api/sendRegistrationNotification', {
        projectName: projectName,
        website: website,
        description: description,
        achievementsRequest: achievmentsReq,
        challenges: challenges,
        supportType: supportType,
        doxxedProfiles: doxxedProfiles,
        socialChannels: socialChannels,
        contactPerson: contactPerson,
    })
        .then(response => {
        })
}
