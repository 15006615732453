import React, { useState, useEffect, useRef } from 'react';
import '../assets/scss/_feedback.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { sendRegistrationNotification } from '../controllers/NotificationController';

export default function Feedback() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [questionsCompleted, setQuestionsCompleted] = useState(false);
    const [timer, setTimer] = useState(5); // Set the initial countdown time
    const isGoToProjectClicked = useRef(false);

    const [projectName, setProjectName] = useState(params.get('id'));
    const [websiteLink, setWebsiteLink] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [achievementsRequest, setAchievementsRequest] = useState('');
    const [challengesDescription, setChallengesDescription] = useState('');
    const [supportDescription, setSupportDescription] = useState('');
    const [doxxedProfiles, setDoxxedProfiles] = useState('');
    const [socialChannels, setSocialChannels] = useState('');
    const [contactPerson, setContactPerson] = useState('');

    const navigate = useNavigate();

    const renderCounter = (counter) => {
        if (params.get('id') === null) {
            return counter + 1;
        } else return counter;
    };

    const handleSubmit = async() => {
        try {
            if (process.env.REACT_APP_ENV === "PROD") {
                await sendRegistrationNotification(
                    projectName,
                    websiteLink,
                    projectDescription,
                    achievementsRequest,
                    challengesDescription,
                    supportDescription,
                    doxxedProfiles,
                    socialChannels,
                    contactPerson
                );
                params.get("id") === null ? navigate('/') : navigate('/dfo-page?id=' + projectName);
            } else {
                navigate('/dfo-page?id=' + projectName);
            }
        } catch (error) {
            console.error("Error sending registration notification:", error);
        }
    };

    useEffect(() => {
        let interval;

        if (questionsCompleted && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (questionsCompleted && timer === 0) {
            // Call handleSubmit when timer reaches zero
            handleSubmit();
        }

        return () => {
            clearInterval(interval);
        };
    }, [questionsCompleted, timer]);

    return (
        <div className="container">
            {questionsCompleted === false ? (
                <div className='questions'>
                    {params.get("id") === null &&
                        <>
                            <div className="-question">
                                <div className="-title">1. What is the name of your project?</div>
                                <input type="text" className='-input'
                                       value={projectName}
                                       onChange={(e) => setProjectName(e.target.value)}
                                />
                            </div>
                        </>
                    }
                    <div className="-question">
                        <div className="-title">{renderCounter(1)}. Hey {projectName} team, can you add a link to your website?</div>
                        <input type="text" className='-input'
                               value={websiteLink}
                               onChange={(e) => setWebsiteLink(e.target.value)}
                        />
                    </div>

                    <div className="-question">
                        <div className="-title">{renderCounter(2)}. Can you give us a short description of your project?</div>
                        <div className="-text">Describe the type of dapp, the ecosystem you are building on, and add links to the project's docs.</div>
                        <input type="text" className='-input'
                               value={projectDescription}
                               onChange={(e) => setProjectDescription(e.target.value)}
                        />
                    </div>

                    <div className="-question">
                        <div className="-title">{renderCounter(3)}. What do you hope to achieve/improve with our help?</div>
                        <div className="-text">E.g. Expand user base, ecosystem-relevant partnerships, product improvements, etc. The more specific you are, the better we'll be able to help!</div>
                        <input type="text" className='-input'
                               value={achievementsRequest}
                               onChange={(e) => setAchievementsRequest(e.target.value)}
                        />
                    </div>

                    <div className="-question">
                        <div className="-title">{renderCounter(4)}. What are your current challenges/bottlenecks?</div>
                        <div className="-text">E.g. Marketing, BD, team building, fundraising, community engagement, etc.</div>
                        <input type="text" className='-input'
                               value={challengesDescription}
                               onChange={(e) => setChallengesDescription(e.target.value)}
                        />
                    </div>

                    <div className="-question">
                        <div className="-title">{renderCounter(5)}. What type of support are you looking for?</div>
                        <div className="-text">Describe the type of collaboration or support you expect from us. Specify any resources you may need, such as mentorship, funding, intros, technical support, etc.</div>
                        <input type="text" className='-input'
                               value={supportDescription}
                               onChange={(e) => setSupportDescription(e.target.value)}
                        />
                    </div>

                    <div className="-question">
                        <div className="-title">{renderCounter(6)}. Is your team doxxed? If so please add their Linkedin handles below</div>
                        <div className="-text">If your team is pseudonymous, just enter their Twitter handles.</div>
                        <input type="text" className='-input'
                               value={doxxedProfiles}
                               onChange={(e) => setDoxxedProfiles(e.target.value)}
                        />
                    </div>

                    <div className="-question">
                        <div className="-title">{renderCounter(7)}. Please add links to your social channels</div>
                        <div className="-text">Twitter, Discord, and any other accounts you are building an audience on</div>
                        <input type="text" className='-input'
                               value={socialChannels}
                               onChange={(e) => setSocialChannels(e.target.value)}
                        />
                    </div>

                    <div className="-question">
                        <div className="-title">{renderCounter(8)}. Person of contact?</div>
                        <div className="-text">Please add the TG name and/or email of the person we can stay in contact with</div>
                        <input type="text" className='-input'
                               value={contactPerson}
                               onChange={(e) => setContactPerson(e.target.value)}
                        />
                    </div>

                    <button className='-button' onClick={() => setQuestionsCompleted(true)}>Submit</button>
                </div>
            ) : (
                <div className="questions offset center">
                    <div className="-title">Thank you for your response!</div>
                    <div className='-text'>
                        Redirecting to project in {timer} seconds...
                    </div>
                    <button
                        className="-button"
                        onClick={() => {
                            isGoToProjectClicked.current = true;
                            handleSubmit();
                        }}
                    >
                        Go to project
                    </button>
                </div>
            )}
        </div>
    );
}
