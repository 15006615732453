import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useValidFile} from "../../hooks/useValidFile";
import "../../assets/scss/_modal.scss"
import axios from "axios";
import * as ethers from "ethers";
import convertToBase64 from "../../utils/FileConverter";
import {toast} from "react-toastify";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from "@mui/material/Checkbox";
import {getUserProfile, saveCurator, saveUserProfile} from "../../controllers/UserProfileController";
import rolesLabels from "../../json/rolesLabels";


const customStyles = {
    content: {
        maxWidth: 600,
        width: '35%',
        maxHeight: '90vh',
        overflowY: 'auto',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0px',
        marginRight: '-50%',
        border: '2px solid #000',
        transform: 'translate(-50%, -50%)',
    },
};
export default function EditProfile(props) {
    const fileDrop = useValidFile(null);

    useEffect(() => {
        setUserData({
            nickName: props.userData.nickName,
            facebook: props.userData.facebook,
            discord: props.userData.discord,
            telegram: props.userData.telegram,
            twitter: props.userData.twitter,
            youtube: props.userData.youtube,
            linkedin: props.userData.linkedin,
            aboutme: props.userData.aboutme,
            email: props.userData.email
        });
        setRoles(props.userRoles);
        setUserRole(props.userData.userRole);
        setPreferredSocialMedia(props.userData.preferredSocialMedia);
    }, [props.userData])

    const [userData, setUserData] = useState({
        walletAddress: props.userData.walletId,
        nickName: "",
        facebook: "",
        discord: "",
        telegram: props.userData.telegram,
        twitter: props.userData.twitter,
        youtube: props.userData.youtube,
        linkedin: props.userData.linkedin,
        aboutme: props.userData.aboutme,
    })

    const rolesKeys = Object.keys(rolesLabels);
    const [roles, setRoles] = useState(rolesKeys.reduce((json, key) => {
        json[key] = false;
        return json;
    }, {})
    )
    const [userRole, setUserRole] = useState("Curator");
    const [preferredSocialMedia, setPreferredSocialMedia] = useState(props.userData.preferredSocialMedia);
    const [publicAccess, setPublicAccess] = useState(props.userData.publicAccess);
    const handleChangeUserRole = (event) => setUserRole(event.target.value);
    const handleChangePreferredSocial = (event) => setPreferredSocialMedia(event.target.value);
    const handleRolesChange = (event) => setRoles({
        ...roles,
        [event.target.name]: event.target.checked,
    });
    const [walletAddress, setWalletAddress] = useState("")
    const [newWalletAddress, setNewWalletAddress] = useState("")

    const handleChangePublicAccess = () => {
        setPublicAccess(!publicAccess)
    }

    const submit = async(e) => {
        e.preventDefault();

        const data = new FormData(e.target);
        const provider = new ethers.BrowserProvider(window.ethereum);
        const address = await provider.getSigner().getAddress();

        let base64;
        if (fileDrop.value === null) {
            base64 = props.userData.avatarLogo
        } else {
            base64 = await convertToBase64(fileDrop.value);
        }
        // TODO : generate ref link
        const refLink = "555"

        if (props.isAdmin){
            await saveCurator(newWalletAddress, walletAddress, userData, roles, preferredSocialMedia, base64, userRole, publicAccess)
                .then(() => {
                    toast.success("Curator saved");
                    props.close();
                });
        } else {
            await saveUserProfile(address, userData, roles, preferredSocialMedia, base64, refLink)
                .then(() => {
                    toast.success("Profile updated")
                    props.updateAvatar();
                    props.close();
                })
                .catch(() => {
                    toast.error("Unauthorized")
                    props.close();
                });
        }
    }

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setUserData((prev) => {
            return {
                ...prev,   // Spread Operator
                [name]: value
            }
        })
    }

    const handleChangeNewWalletAddress = (event) => {
        setNewWalletAddress(event.target.value)
    }

    const handleChangeWalletAddress = async (event) => {
        setWalletAddress(event.target.value);
        await getUserProfile(event.target.value)
            .then(response => {
                console.log('TOKEN DESIGN ' + response.data.isTokenDesign)
                if (response.status === 204){
                    const emptyProperties = ["nickName", "facebook", "discord", "telegram", "twitter", "youtube", "linkedin", "aboutme", "email"];
                    const emptyUserData = emptyProperties.reduce((acc, property) => {
                        acc[property] = "";
                        return acc;
                    }, {});
                    setUserData(emptyUserData);
                    const roleKeys = Object.keys(rolesLabels);

                    const emptyUserRoles = roleKeys.reduce((acc, property) => {
                        acc[property] = false;
                        return acc;
                    }, {});
                    setRoles(emptyUserRoles);
                } else{
                    setUserData ({
                        nickName: response.data.nickName,
                        walletAddress: response.data.walletAddress,
                        facebook: response.data.facebook,
                        discord: response.data.discord,
                        telegram: response.data.telegram,
                        twitter: response.data.twitter,
                        youtube: response.data.youtube,
                        linkedin: response.data.linkedin,
                        aboutme: response.data.aboutme,
                        userRole: response.data.userRole,
                        email: response.data.email,
                        preferredSocialMedia: response.data.preferredSocialMedia,
                    })
                    setPublicAccess(response.data.publicAccess)
                    setRoles( {
                        isFundraisingAdvisory: response.data.isFundraisingAdvisory,
                        isStrategicAdvisory: response.data.isStrategicAdvisory,
                        isLegalAdvisory: response.data.isLegalAdvisory,
                        isTechnicalAdvisory: response.data.isTechnicalAdvisory,
                        isFinancialAdvisory: response.data.isFinancialAdvisory,
                        isProductAdvisory: response.data.isProductAdvisory,
                        isMarketingAdvisory: response.data.isMarketingAdvisory,
                        isTokenomicsAdvisory: response.data.isTokenomicsAdvisory,
                        isGotoMarketStrategy: response.data.isGotoMarketStrategy,
                        isGrowthStrategy: response.data.isGrowthStrategy,
                        isListings: response.data.isListings,
                        isLiquidity: response.data.isLiquidity,
                        isInfrastructure: response.data.isInfrastructure,
                        isSmartContracts: response.data.isSmartContracts,
                        isNFT: response.data.isNFT,
                        isMetaverse: response.data.isMetaverse,
                        isGameFi: response.data.isGameFi,
                        isMarketingExecution: response.data.isMarketingExecution,
                        isPR: response.data.isPR,
                        isContentCreation: response.data.isContentCreation,
                        isTwitterKOL: response.data.isTwitterKOL,
                        isYoutubeKOL: response.data.isYoutubeKOL,
                        isInfluencer: response.data.isInfluencer,
                        isAmbassador: response.data.isAmbassador,
                        isCommunityBuilding: response.data.isCommunityBuilding,
                        isCommunityManagement: response.data.isCommunityManagement,
                        isDesign: response.data.isDesign,
                        isUIUX: response.data.isUIUX,
                        isBusinessModel: response.data.isBusinessModel,
                        isPitchDeck: response.data.isPitchDeck,
                        isWhitepaper: response.data.isWhitepaper,
                        isHrRecruiting: response.data.isHrRecruiting,
                        isDefi: response.data.isDefi,
                        isAi: response.data.isAi,
                        isTokenDesign: response.data.isTokenDesign
                    })
                }
        })
    }

    return (
        <div>
            <Modal
                isOpen={props.show}
                onRequestClose={props.close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='modal-content'>
                    <form onSubmit={submit}>
                        {props.isAdmin &&
                            <>
                                <div>
                                    <div className='-text'>Public access</div>
                                    <input
                                        type="checkbox"
                                        className="-checkbox"
                                        checked={publicAccess}
                                        onChange={handleChangePublicAccess}
                                    />
                                </div>

                                <div>
                                    <div className='-text'>Wallet address</div>
                                    <input
                                        type="text"
                                        className='-input'
                                        name='walletAddress'
                                        value={userData.walletAddress}
                                        onChange={handleChangeWalletAddress}
                                    />
                                </div>
                                <div>
                                    <div className='-text'>New Wallet address</div>
                                    <input
                                        type="text"
                                        className='-input'
                                        name='newWalletAddress'
                                        value={newWalletAddress}
                                        onChange={handleChangeNewWalletAddress}
                                    />
                                </div>
                            </>
                        }
                        <div>
                            <div className='-text'>Username</div>
                            <input
                                type="text"
                                className='-input'
                                name='nickName'
                                value={userData.nickName}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <div className='-text'>Email</div>
                            <input
                                type="text"
                                className='-input'
                                name='email'
                                value={userData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <div className='-text'>Avatar</div>
                            <label htmlFor='choose-file' className='-dropdown'>
                                {!fileDrop.isUploaded ?
                                    <>
                                        <div className='-text bold'>Drop your profile logo here or <span
                                            className='-accent'>browse</span></div>
                                        <div className='-text'>.PNG on transparent background<br/></div>
                                    </>
                                    :
                                    <img src={fileDrop.file} className='-img' alt=""/>
                                }
                                <input type="file" className='-input' id='choose-file'
                                       accept=".jpg, .jpeg, .png, .pdf, .svg" onChange={e => fileDrop.onChange(e)}/>
                            </label>
                        </div>
                        <a href="https://t.me/FORCEFIbot" className="-link"  target='_blank'>
                            <div className='-button small-font white center'>
                                Subscribe to Forcefi to receive messages on telegram
                            </div>
                        </a>
                        <div className='sync-social'>
                            <div className='-text'>Telegram</div>
                            <input
                                type="text"
                                className='-input'
                                name='telegram'
                                value={userData.telegram}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='sync-social'>
                            <div className='-text'>Twitter</div>
                            <input
                                type="text"
                                className='-input'
                                name='twitter'
                                value={userData.twitter}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='sync-social'>
                            <div className='-text'>Discord</div>
                            <input
                                type="text"
                                className='-input'
                                name='discord'
                                value={userData.discord}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='sync-social'>
                            <div className='-text'>Youtube</div>
                            <input
                                type="text"
                                className='-input'
                                name='youtube'
                                value={userData.youtube}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='sync-social'>
                            <div className='-text'>Facebook</div>
                            <input
                                type="text"
                                className='-input'
                                name='facebook'
                                value={userData.facebook}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='sync-social'>
                            <div className='-text'>Linkedin</div>
                            <input
                                type="text"
                                className='-input'
                                name='linkedin'
                                value={userData.linkedin}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='-text'>Preferred social media</div>

                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={preferredSocialMedia}
                                onChange={handleChangePreferredSocial}
                            >
                                <FormControlLabel value="discord" control={<Radio />} label="Discord" />
                                <FormControlLabel value="telegram" control={<Radio />} label="Telegram" />
                            </RadioGroup>
                        </FormControl>

                        <div className='-text xs'>About me</div>
                        <textarea
                            type="text"
                            className='-textarea'
                            name='aboutme'
                            maxLength="250"
                            value={userData.aboutme}
                            onChange={handleChange}
                        />
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={userRole}
                                onChange={handleChangeUserRole}
                            >
                                <FormControlLabel value="user" control={<Radio />} label="User" />
                                <FormControlLabel value="Curator" control={<Radio />} label="Curator" />
                                {props.isAdmin &&
                                    <FormControlLabel value="Viewer" control={<Radio />} label="Viewer" />
                                }
                                <FormControlLabel
                                    disabled
                                    value="angelInvestor"
                                    control={<Radio />}
                                    label="Angel Investor"
                                />
                            </RadioGroup>
                        </FormControl>

                        {userRole === 'Curator' &&
                        <div className='checkboxes'>
                            {rolesKeys.map( (role, key) =>
                                <FormControlLabel key={key} label={rolesLabels[role]}
                                                   control={<Checkbox checked={roles[role]} onChange={handleRolesChange} name={role} />}
                                />
                            )}
                        </div>
                        }
                        <button className='-button' >Submit</button>
                    </form>
                </div>
            </Modal>
        </div>
    );
}
