import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import '../../assets/scss/_add-beneficiar-modal.scss';
import { isAddress } from 'ethers';

export default function BeneficiarManager({beneficiars, index, handleBeneficiarChange, setIsBenificiarValid, validateForm, benificiarTrigger}) {
    const [beneficiaries, setBeneficiaries] = useState(beneficiars);
    const [errors, setErrors] = useState(beneficiars || []);


    // useEffect(() => {
    //     checkFormValidity(beneficiaries, errors);
    // }, [beneficiaries, errors, benificiarTrigger]);
    useEffect(() => {
        validateAllForms();
    }, [benificiarTrigger]);

    const handleChange = (id, fieldName, value) => {
        const updatedBeneficiaries = [...beneficiaries];
        const updatedErrors = [...errors];
        updatedBeneficiaries[id][fieldName] = value;

        if (fieldName === 'address') {
            updatedErrors[id].address = isAddress(value) ? '' : 'Invalid address';
        }

        if (fieldName === 'amount') {
            updatedErrors[id].amount = value.trim() !== '' ? '' : 'Amount cannot be empty';
        }

        setBeneficiaries(updatedBeneficiaries);
        handleBeneficiarChange(index, updatedBeneficiaries);
        setErrors(updatedErrors);
        checkFormValidity(updatedBeneficiaries, updatedErrors);
    };

    const addBeneficiary = () => {
        const updatedBeneficiaries = [...beneficiaries, { address: '', amount: '' }];
        setBeneficiaries(updatedBeneficiaries);
        setErrors([...errors, { address: '', amount: '' }]);
        handleBeneficiarChange(index, updatedBeneficiaries);
        validateForm();
        checkFormValidity(beneficiaries, errors);
    };

    const removeBeneficiary = (id) => {
        const updatedBeneficiaries = [...beneficiaries];
        const updatedErrors = [...errors];
        updatedBeneficiaries.splice(id, 1);
        updatedErrors.splice(id, 1);
        setBeneficiaries(updatedBeneficiaries);
        setErrors(updatedErrors);
        checkFormValidity(updatedBeneficiaries, updatedErrors);
        handleBeneficiarChange(index, updatedBeneficiaries);
        validateForm();
        // validateBeneficiary();
    };

    const checkFormValidity = (beneficiaries, errors) => {
        const isValid = beneficiaries.every((b, index) => {
            return isAddress(b.address) && b.amount.trim() !== '';
        }) && errors.every(err => err.address === '' && err.amount === '');
        setIsBenificiarValid(isValid);
    };

    const validateAllForms = () => {
        const updatedErrors = beneficiaries.map(beneficiary => ({
            address: isAddress(beneficiary.address) ? '' : 'Invalid address',
            amount: beneficiary.amount.trim() !== '' ? '' : 'Amount cannot be empty'
        }));
        setErrors(updatedErrors);
        checkFormValidity(beneficiaries, updatedErrors);
    };


    return (
        <div>
            {beneficiaries.length > 0 &&
                <div className="add-beneficiar">
                    <div className="-container">
                        <div className="-text">Address:</div>
                    </div>
                    <div className="-container">
                        <div className="-text">Amount:</div>
                    </div>
                </div>
            }
            {beneficiaries.map((beneficiary, index) => (
                <div key={index} className="add-beneficiar">
                    <div>
                        <Input
                            value={beneficiary.address}
                            onChange={(e) => handleChange(index, 'address', e.target.value)}
                            placeholder="Enter address"
                            status={errors[index]?.address ? 'error' : ''}
                        />
                        {errors[index].address && <div className="-text error">{errors[index].address}</div>}
                    </div>
                    <div>
                        <Input
                            value={beneficiary.amount}
                            onChange={(e) => handleChange(index, 'amount', e.target.value)}
                            placeholder="Enter amount"
                            status={errors[index]?.amount ? 'error' : ''}
                            type="number"
                        />
                        {errors[index].amount && <div className="-text error">{errors[index].amount}</div>}
                    </div>
                    <Button className="remove-btn" danger onClick={() => removeBeneficiary(index)}>&#x2715;</Button>
                </div>
            ))}
            <div className="-right">
                <Button onClick={addBeneficiary} type="primary">Add beneficiar</Button>
            </div>
        </div>
    );
}
