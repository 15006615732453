import React, {useEffect, useState} from 'react';
import {Button, Input, Table} from 'antd';
import {getProjectTokens} from "../../ethers/ContractFactory";
import {getTokenDecimals, getTokenName, getTokenTicker, getTotalSupply} from "../../ethers/ERC20Contract";
import Web3 from "web3";
import ShortAddress from "../elements/ShortAddress";
import { isAddress } from 'ethers'
const TokenSelected = ({selectedToken, setSelectedToken, projectName, setDecimals}) => {

    const[dataSource, setDataSource] = useState([])
    const[erc20TokenAddresses, setErc20TokenAddresses] = useState([])
    const handleSelectToken = (record) => {
        setSelectedToken(record.tokenAddress);
        setDecimals(record.decimals)
    }
    const [tokenAddress, setTokenAddress] = useState('');
    const [tokenAddressError, setTokenAddressError] = useState('');

    const handleChangeTokenAddress = (e) => {
        const address = e.target.value;
        setTokenAddress(address);
        if (isAddress(address)) setTokenAddressError('');
        else setTokenAddressError('Invalid address');
    };

    useEffect(() => {
        handleGetProjectTokens()
    },[])

    useEffect(() => {
        handleSetDataSource()
    },[erc20TokenAddresses])

    const columns = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Token name',
            dataIndex: 'tokenName',
            key: 'tokenName',
        },
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
        },
        {
            title: 'Total Supply',
            dataIndex: 'totalSupply',
            key: 'totalSupply',
        },
        {
            title: 'Token address',
            dataIndex: 'tokenAddress',
            key: 'tokenAddress',
            render: (text, record) => (
                <ShortAddress address={text} />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div>
                    <Button
                        style={{ width: '90px' }}
                        type={record.tokenAddress === selectedToken ? 'primary ghost' : 'default'}
                        onClick={
                            !selectedToken || record.tokenAddress !== selectedToken
                                ? () => handleSelectToken(record)
                                : () => setSelectedToken('')
                        }
                    >
                        { !selectedToken || record.tokenAddress !== selectedToken ? 'Select' : 'Selected' }
                    </Button>
                </div>
            ),
        },
    ];

    const handleGetProjectTokens = async () => {
        const erc20AddressesFromChain = await getProjectTokens(projectName);
        setErc20TokenAddresses(erc20AddressesFromChain)
    }

    const handleSetDataSource = async () => {
        const dataFromBlockchain = [];
        for (const erc20Address of erc20TokenAddresses){
            dataFromBlockchain.push({
                index: 1,
                ticker: await getTokenTicker(erc20Address),
                totalSupply: Web3.utils.fromWei((await getTotalSupply(erc20Address)).toString(), "ether"),
                tokenName: await getTokenName(erc20Address),
                decimals: await getTokenDecimals(erc20Address),
                tokenAddress: erc20Address,
            })
        }
        setDataSource(dataFromBlockchain)
    }

    const handleAddNewTokenAddress = () => {
        setErc20TokenAddresses(prevAddresses => [...prevAddresses, tokenAddress]);
    }

    return (
        <>
            <div>
                <div className="-title m">
                    Token Selection Block
                </div>
                <div className="-text">
                    Choose one of the tokens you previously created, or add a token manually by connecting your wallet.
                </div>
            </div>
            <div>
                <div className="-title s">
                    Token List
                </div>
                <div className="-text">
                    Pick one of the tokens below by clicking on Select. You will then be able to manually adjust the Vesting period.
                </div>
            </div>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowKey={(record, index) => index}
            />

            <div>
                <div className="-title s">
                    Add Token Contract
                </div>
                <div className="-text">
                    Need to manually add a token in the Token List? Head over to your wallet, locate your token, and add its token address in the section below.
                </div>
            </div>
            <div>
                <div className="-text black offset">Enter token address to add it to Available tokens</div>
                <Input placeholder='any' onChange={handleChangeTokenAddress}/>
                {tokenAddressError && <div className="-text error">{tokenAddressError}</div>}
            </div>
            <div>
                <Button type="primary" onClick={handleAddNewTokenAddress}>Add Token</Button>
            </div>
        </>
    );
};

export default TokenSelected;
