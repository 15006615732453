// tokenHandler.js
import * as ethers from "ethers";
import ERC20Token from "../abis/ERC20Token.json";
import ERC20MintableToken from "../abis/ERC20MintableToken.json";

export async function approveAmount (erc20Address, spender, amount, decimals) {

    const defaultProvider = ethers.getDefaultProvider();

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);

        let normalizedAmount = amount;
        if (decimals){
            const nr = (amount * (10 ** Number(decimals))).toLocaleString();
            normalizedAmount = nr.replace(/,/g, '')
        }

        const vestingContract = new ethers.Contract(erc20Address, ERC20Token, signer);

        const tx = await vestingContract.approve(spender, normalizedAmount);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

export async function mintTestTokens (erc20Address, mintAmount) {

    const defaultProvider = ethers.getDefaultProvider();

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);

        const vestingContract = new ethers.Contract(erc20Address, ERC20MintableToken, signer);

        const tx = await vestingContract.mint(mintAmount);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
};


export async function getTokenTicker (erc20Address) {

    const defaultProvider = ethers.getDefaultProvider();

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);

        const erc20TokenContract = new ethers.Contract(erc20Address, ERC20Token, provider);

        return await erc20TokenContract.symbol();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getTokenName (erc20Address) {

    const defaultProvider = ethers.getDefaultProvider();

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);

        const erc20TokenContract = new ethers.Contract(erc20Address, ERC20Token, provider);

        return await erc20TokenContract.name();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getTokenDecimals (erc20Address) {

    const defaultProvider = ethers.getDefaultProvider();

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);

        const erc20TokenContract = new ethers.Contract(erc20Address, ERC20Token, provider);

        return await erc20TokenContract.decimals();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getTotalSupply (erc20Address) {

    const defaultProvider = ethers.getDefaultProvider();

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);

        const erc20TokenContract = new ethers.Contract(erc20Address, ERC20Token, provider);

        return await erc20TokenContract.totalSupply();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getAllowance (erc20Address, owner, spender) {

    const defaultProvider = ethers.getDefaultProvider();

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);

        const erc20TokenContract = new ethers.Contract(erc20Address, ERC20Token, provider);

        return await erc20TokenContract.allowance(owner, spender);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}
