import React, { useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import '../assets/scss/_burger.scss';
import Open from "../assets/icons/header/open.svg";
import Close from "../assets/icons/header/close.svg";
import {Web3Button} from "@web3modal/react";
import {useAccount} from "wagmi";

const Burger = () => {
    const [open, setOpen] = useState(false);
    const { isConnected } = useAccount()

    const handleClick = () => {
        setOpen(!open);
        open ? document.body.style.overflow = 'unset': document.body.style.overflow = 'auto';
    }
    const closeMenu = () => setOpen(false);

    const NavItem = (props) => {
        return(
            <li className="-item">
                {/*<NavLink to={props.link} onClick={closeMenu} className={({ isActive }) => (isActive ? 'current' : 'inactive')} >{props.title}</NavLink>*/}
            </li>
        )
    };

    return (
        <div className="burger fixed-top">
            <div onClick={handleClick} className="-icon">
                {/*{open ? "x" : "y"}*/}
                <img src={Open} alt=""/>
            </div>
            <div className={open ? '-links active' : '-links'}>
                        <div onClick={handleClick}>
                         <img src={Close} alt="" />
                        </div>
                {isConnected && <Link className='-item' to='/registration'>List new project</Link>}
                        <Link className='-item' to='/projects'>Projects</Link>
                        <Link className='-item' to='/curators'>Curators</Link>
                        <Link className='-item' to='/cabinet'>Cabinet</Link>
                        <Link className='-item' to='/partners'>Partner</Link>

                        {/*{hasCurratorAccess &&*/}
                        {/*    <Link className='-item' to='/curators'>Curators</Link>*/}
                        {/*}*/}
                       {/* <Link to='/cabinet'><button className="app-button">App</button></Link>*/}
                       {/*<Web3Button/>*/}
            </div>
        </div>
    );
};
export default Burger;
