import React from "react";
import "./../assets/css/spinner.css";

export default function LoadingSpinner({text}) {
    return (
        <div className="spinner-container">
            {(text !== undefined) &&
                <div className="spiner-text">{text}</div>
            }
            <div className="lds-dual-ring">
            </div>
        </div>
    );
}
