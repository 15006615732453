import React, { useState } from 'react';
import { Button, Modal, Steps, Spin } from 'antd';
import {approveAmount} from "../../ethers/ERC20Contract";
import erc20InvestmentTokens from "../../constants/ERC20InvestmentTokens";
import {invest} from "../../ethers/Fundraising";
import Lock from '../../assets/icons/metamaskProgress/lock.svg';
import Token from '../../assets/icons/metamaskProgress/token.svg';
import Done from '../../assets/icons/metamaskProgress/finish.svg';
export default function MetamaskProgress({show, close, step=0, selectedToken, decimals, investmentAmount, saleUUID} ) {

    const handleInvest = async () => {
        await approveAmount(selectedToken, erc20InvestmentTokens.SEPOLIA.FUNDRAISING_ADDRESS, (investmentAmount * (10 ** Number(decimals))).toString())
        step = 1
        await invest(investmentAmount, selectedToken, saleUUID)
        step = 2
    }
    const imgStyle = {
        width: '24px',
        height: '34px',
    };
    const icons = [Token, Lock, Done];
    const getIcon = (currentStep, stepNumber) => {
        return currentStep === stepNumber
            ? <Spin tip="Loading" />
            : <img src={icons[stepNumber]} style={imgStyle} alt="Step Icon" />;
    };

    return (
        <Modal
            title="Progression of the Process
            "
            open={show}
            onOk={handleInvest}
            onCancel={close}
            footer={null}
            cancelButtonProps={{
                disabled: false,
            }}
            okButtonProps={{
                disabled: false,
            }}

        >
            <div className="modal">
                <Steps
                    current={step}
                    items={[
                        {
                            title: 'Approve tokens',
                            icon: getIcon(step, 0)
                        },
                        {
                            title: 'Lock In',
                            icon: getIcon(step, 1)
                        },
                        {
                            title: 'Finish',
                            icon: getIcon(step, 2)
                        },
                    ]}
                />
            </div>
        </Modal>
    );
};
