import React, {useEffect, useState} from 'react';
import "../assets/scss/_cabinet.scss";
import "../assets/scss/_components.scss";
import "../assets/scss/_layout.scss"
import {Tabs} from 'antd';
import axios from "axios";
import EditProfile from "../components/modals/EditProfile";
import {useAccount, useNetwork} from 'wagmi';
import TestnetUserWhitelistModal from "../components/modals/TestnetUserWhitelistModal";
import {getUserProfile} from "../controllers/UserProfileController";
import NotificationsMessage from "../components/NotificationsMessage";
import UsersSettingsPage from "./UsersSettings/UsersSettingsPage";
import CompanyCard from "../components/CompanyCard";
import Skills from "../components/user/Skills";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CreateSpaceIdModal from "../components/modals/CreateSpaceIdModal";
import Avatar from "../components/user/Avatar";
import Description from "../components/user/Description";

export default function Cabinet() {
    const [isLoading, setIsLoading] = useState(true);
    const [spaceIdModal, setSpaceIdModal] = useState(false);
    const { TabPane } = Tabs;

    const [userData, setUserData] = useState({});
    const [userLogo, setUserLogo] = useState("https://media.istockphoto.com/id/1131164548/vector/avatar-5.jpg?s=612x612&w=0&k=20&c=CK49ShLJwDxE4kiroCR42kimTuuhvuo2FH5y_6aSgEo=");

    const [userRoles, setUserRoles] = useState();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [allowWhitelist, setAllowWhitelist] = useState(false);
    const [myOwnedCompanies, setMyOwnedCompanies] = useState([]);
    const [companiesIFollow, setCompaniesIFollow] = useState([]);

    const [editDataModal, setEditDataModal] = useState(false);
    const [addCurator, setAddCurator] = useState(false);
    const [showWhitelistUserModal, setShowWhitelistUserModal] = useState(false);

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        getDfos();
        getCompaniesIFollow();
        geActiveUserProfile();
        const addresses = process.env.REACT_APP_TESTNET_WHITELISTER_ADDRESS.split(',');
        for (const addressFromEnv of addresses){
            if (address === addressFromEnv){
                setAllowWhitelist(true);
            }
        }
    }, [])
    useEffect(() => {
        getNotifications()
    }, [])

    const { chain } = useNetwork()

    const getNotifications = async () => {
        await axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/getReceivedNotifications', {params: {receiptAddress: address}})
            .then((response) => {
                const notificationsFromDb = [];
                response.data.forEach(async (notification) => {
                    notificationsFromDb.push(<NotificationsMessage notification={notification}/>)
                })
                setNotifications(notificationsFromDb)
            })
    }

    const geActiveUserProfile = async () => {
        await getUserProfile(address)
            .then(response => {
                setUserData ({
                        nickName: response.data.nickName,
                        walletId: address,
                        facebook: response.data.facebook,
                        discord: response.data.discord,
                        telegram: response.data.telegram,
                        twitter: response.data.twitter,
                        youtube: response.data.youtube,
                        linkedin: response.data.linkedin,
                        aboutme: response.data.aboutme,
                        email: response.data.email,
                        userRole: response.data.userRole,
                        refLink: response.data.refLink,
                        preferredSocialMedia: response.data.preferredSocialMedia,
                })
                setUserRoles( {
                    isFundraisingAdvisory: response.data.isFundraisingAdvisory,
                    isStrategicAdvisory: response.data.isStrategicAdvisory,
                    isLegalAdvisory: response.data.isLegalAdvisory,
                    isTechnicalAdvisory: response.data.isTechnicalAdvisory,
                    isFinancialAdvisory: response.data.isFinancialAdvisory,
                    isProductAdvisory: response.data.isProductAdvisory,
                    isMarketingAdvisory: response.data.isMarketingAdvisory,
                    isTokenomicsAdvisory: response.data.isTokenomicsAdvisory,
                    isGotoMarketStrategy: response.data.isGotoMarketStrategy,
                    isGrowthStrategy: response.data.isGrowthStrategy,
                    isListings: response.data.isListings,
                    isLiquidity: response.data.isLiquidity,
                    isInfrastructure: response.data.isInfrastructure,
                    isSmartContracts: response.data.isSmartContracts,
                    isNFT: response.data.isNFT,
                    isMetaverse: response.data.isMetaverse,
                    isGameFi: response.data.isGameFi,
                    isMarketingExecution: response.data.isMarketingExecution,
                    isPR: response.data.isPR,
                    isContentCreation: response.data.isContentCreation,
                    isTwitterKOL: response.data.isTwitterKOL,
                    isYoutubeKOL: response.data.isYoutubeKOL,
                    isInfluencer: response.data.isInfluencer,
                    isAmbassador: response.data.isAmbassador,
                    isCommunityBuilding: response.data.isCommunityBuilding,
                    isCommunityManagement: response.data.isCommunityManagement,
                    isDesign: response.data.isDesign,
                    isUIUX: response.data.isUIUX,
                    isBusinessModel: response.data.isBusinessModel,
                    isPitchDeck: response.data.isPitchDeck,
                    isWhitepaper: response.data.isWhitepaper,
                    isHrRecruiting: response.data.isHrRecruiting,
                    isDefi: response.data.isDefi,
                    isAi: response.data.isAi,
                    isTokenDesign: response.data.isTokenDesign
                })

                getCompaniesIFollow(response.data.followCompanies)
            })
            await axios.get(apiUrl + '/api/getUserProfileLogo', { params: { walletAddress: address } })
                .then((response) =>{
                    if (response.data.avatarLogo !== undefined){
                        setUserLogo(response.data.avatarLogo)
                    }
                    setIsLoading(false);
                });

     }
    const { address } = useAccount();

    const getDfos = async() => {
        setMyOwnedCompanies(await getDfoCompanyData(address));
    };

    const getCompaniesIFollow = async(mainWalletAddresses) => {
        const companiesIFollowFromDb = []
        for (const mainWalletAddress of mainWalletAddresses) {
            await axios.get(apiUrl + '/api/getDfoCompanyById', { params: { mainWalletAddress: mainWalletAddress } })
                .then(dfo => dfo.data !== null && companiesIFollowFromDb.push(<CompanyCard item={dfo.data} />));
        }
        setCompaniesIFollow(companiesIFollowFromDb);
    };

    const getDfoCompanyData = async(myDfoCompanies) => {
        const myDfoList = [];
        const dfos = await axios.get(apiUrl + '/api/getDfoCompaniesByOwner', { params: { owner: address } })
            .then(response => {
                for (const dfo of response.data) {
                    if (dfo !== null)  myDfoList.push(<CompanyCard item={dfo} />);
                }
            });
        return myDfoList;
    }

    const handleCloseEditModal = () => {
        setEditDataModal(false);
    }

    return (
        <>
            <div className="back home"></div>
            <div className="main-container">
                <div className='user-data'>
                    <div className='-container'>
                    {isLoading ?
                        <div className='-user-info-block'>
                            <Skeleton variant="rounded" animation="wave" classes='-image' height={200} width={200} />
                            <Stack spacing={1}>
                                <Skeleton variant="rectangular" animation="wave" height={25} width={200} />
                                <Skeleton variant="rectangular" animation="wave" height={15} width={300} />
                                <Skeleton variant="rectangular" animation="wave" height={15} width={300} />
                                <Skeleton variant="rectangular" animation="wave" height={40} width={300} />
                                <Skeleton variant="rectangular" animation="wave" height={70} width={250} />
                            </Stack>

                         </div>
                         :
                        <div className='-user-info-block'>
                            <div>
                                <Avatar name={userData.nickName} img={userLogo} socials={userData} disableName={true}/>
                            </div>
                            <div>
                                <div className='-nickName xl bold'>{userData.nickName}</div>
                                {userData?.walletId &&
                                <Description title="Wallet address" text={userData.walletId}/>
                                }
                                {userData?.aboutme  &&
                                   <Description title="Description" text={userData.aboutme}/>
                                }
                                <Skills roles={userRoles} />
                            </div>
                        </div>
                        }
                        <div className='-buttons'>
                            {chain && chain.network === "10243" &&
                                <a href={"https://faucet.arthera.net/"} target={"_blank"}>
                                    <button className='button-silver small-font'>Arthera faucet</button>
                                </a>
                            }
                            {allowWhitelist &&
                                <button className='button-silver small-font'  onClick={() => setShowWhitelistUserModal(true)}>Set curator</button>
                            }
                            {allowWhitelist &&
                                <button className='button-silver small-font'  onClick={() => setAddCurator(true)}>Add/Update curator</button>
                            }
                        </div>
                        <div className='-dfo-container'>
                            <Tabs >
                                {myOwnedCompanies.length !== 0 &&
                                    <TabPane tab="My Projects" key="myProjects">
                                        <div className='-company-wrapper'>
                                            {myOwnedCompanies}
                                        </div>
                                    </TabPane>
                                }
                                <TabPane tab="Followed Projects" key="followedProjects">
                                    <div className='-company-wrapper'>
                                        {companiesIFollow}
                                    </div>
                                    {companiesIFollow.length === 0 && <div>There are no projects you have followed by now</div>}
                                </TabPane>
                                <TabPane tab="Notifications" key="notifications">
                                    <div className='-links-wrapper'>
                                        {notifications}
                                    </div>
                                    {notifications.length === 0 && <div>There are no notifications</div>}
                                </TabPane>
                                <TabPane tab="Settings" key="editData">
                                    <UsersSettingsPage
                                        show={editDataModal}
                                        userData={userData}
                                        isAdmin={false}
                                        close={handleCloseEditModal}
                                        updateAvatar={geActiveUserProfile}
                                        userRoles={userRoles}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <CreateSpaceIdModal show={spaceIdModal} close={() => setSpaceIdModal(false)} mainWalletAddress={userData.walletId} companyName={userData.nickName} isCompany={false}/>
                <EditProfile show={addCurator} userData={{}} userRoles={{}} isAdmin={true} close={() => setAddCurator(false)} />
                <TestnetUserWhitelistModal show={showWhitelistUserModal} close={() => setShowWhitelistUserModal(false)}/>
            </div>
        </>
    );
}
