import React, { useState } from 'react';
import { Button, message } from 'antd';
import '../../assets/scss/_short-address.scss';
const ShortAddress = ({ address }) => {

    const handleCopyAddress = () => {
        navigator.clipboard.writeText(address)
            .then(() => {
                message.success('Address copied to clipboard');
            })
            .catch((error) => {
                console.error('Failed to copy:', error);
                message.error('Failed to copy address');
            });
    };

    const shortenAddress = (address) => {
        if (!address) return '';
        return `${address.slice(0, 4)}...${address.slice(-6)}`;
    };

    return (
        <span onClick={handleCopyAddress} className="short-address">
            {shortenAddress(address)}
        </span>
    );
};

export default ShortAddress;
