import React, {useEffect, useState} from 'react';
import '../assets/scss/_create-token.scss';
import {Input, Divider, Card, Switch, Button, Tag, Select} from "antd";
import NetworkSelect from "../components/token/NetworkSelect";
import VestingConstructor from "../components/token/VestingConstructor";
import TokenSelect from "../components/token/TokenSelect";
import handleAddVestingPlan from "../ethers/Vesting";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingModal from "../components/modals/LoadingModal";
import {approveAmount} from "../ethers/ERC20Contract";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import {toast} from "react-toastify";
import MetamaskProgress from "../components/modal2/MetamaskProgress";
import getCurrentDate from "../utils/getCurrentDate";
import * as ethers from "ethers";

export default function Vesting() {

    const provider = new ethers.BrowserProvider(window.ethereum);
    const [network, setNetwork] = useState('')

    const [successModal, setSuccessModal] = useState(false);
    const [step, setStep] = useState(0);

    const [selectedToken, setSelectedToken] = useState('');
    const [decimals, setDecimals] = useState('');
    const [vestingTrigger, setVestingTrigger] = useState(0);
    const navigate = useNavigate();

    const location = useLocation();
    const projectName = location.state.projectName;

    const[isLoading, setIsLoading] = useState(false)
    const[vestingError, setVestingError] = useState(true)
    const [formValues, setFormValues] = useState([{
        label: "",
        vestingStart: getCurrentDate(),
        vestingPeriod: 0,
        vestingPeriodOption:"day",
        releasePeriod : 0,
        releasePeriodOption: "day",
        lockupPeriod: 0,
        lockupPeriodOption: "day",
        tge: 0,
        tokenAllocated: 0,
        beneficiars: []
    }
    ]);

    useEffect(() => {
        handleSetNetwork()
    }, [])

    const handleSetNetwork = async () => {
        setNetwork(await provider.getNetwork())
    }

    const handleCreateVesting = async () => {
        setVestingTrigger(vestingTrigger+1)
        if (vestingError) {
            return;
        }
        // setIsLoading(true);
        setSuccessModal(true)
        const approveTx = await approveAmount(selectedToken, erc20InvestmentTokens[network.chainId].VESTING_ADDRESS, formValues[0].tokenAllocated, decimals)
        if (approveTx !== undefined){
            const errorMsg = approveTx.toString().split('(')
            toast.error(errorMsg[0])
            setStep(0)
        } else {
            setStep(1)
            const tx = await handleAddVestingPlan(formValues, projectName, selectedToken, decimals);
            // setIsLoading(false);
            if (tx !== undefined){
                const errorMsg = tx.toString().split('(')
                toast.error(errorMsg[0])
                setStep(0)
            } else {
                setStep(2)
                navigate('/dfo-page?id=' + projectName)
            }
        }
    }

    return (
        <>
            <MetamaskProgress
                              decimals={decimals}
                              show={successModal}
                              close={() => setSuccessModal(false)}
                              step={step}/>
        <LoadingModal  show={isLoading} text='Locking funds to Vesting contract...' />
            <div className="main-container">
                <div className='create-token'>
                    <div>
                        <div className="-title">
                            Vesting Period
                        </div>
                        <div className="-text">
                            On this page you can adjust the cliff and vesting period of tokens for sale participants. Vesting periods can be set based on the stage of the sale. Start by highlighting the deployment chain once more.
                        </div>
                    </div>
                    <div>
                        <div className="-text black offset">Select Applicable Chains</div>
                        <NetworkSelect />
                    </div>
                    <Divider />
                    <TokenSelect selectedToken={selectedToken} setSelectedToken={setSelectedToken} projectName={projectName} setDecimals={setDecimals} />
                    <Divider />
                    <VestingConstructor formValues={formValues} setFormValues={setFormValues} setVestingError={setVestingError} trigger={vestingTrigger}/>

                    <div>
                        <div className="-title m">
                            Confirm Token Seetings
                        </div>
                        <div className="-text">
                            Make sure you carefully check each Unlock period to ensure the information you provided is correct.  If everything is correct, you can proceed by clicking on 'Lock'. Do not 'Lock' your vesting contracts if you intend to adjust your tokenomics at a later point in time.
                        </div>
                    </div>
                    <div className="-button-container">
                        <Button disabled={selectedToken === ""} type="primary" onClick={handleCreateVesting}>Create</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

