import convertToBase64 from "../utils/FileConverter";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export async function initializeDfo(_companyName, owner, dfoTicker, dfoType, file, network) {
    try {
        const base64 = await convertToBase64(file);

        return await axios.post(apiUrl + '/api/initializeDFO', {
            companyName: _companyName,
            owner: owner,
            logo: base64,
            ticker: dfoTicker,
            multisigAddresses: [],
            numOfMultisigConfirmations: 1,
            isPrivate: dfoType !== "public",
            network: network
        });
    } catch (error) {
        // Handle errors
        console.error('Error during API request:', error);
        throw error; // Re-throw the error for the caller to handle
    }
}

export async function listProjectInDb(_companyName, owner, mainWallet, blockNumber, network) {

    await axios.post(apiUrl + '/api/listProject', {
        mainWalletAddress: mainWallet,
        owner: owner,
        companyName: _companyName,
        blockNumber : blockNumber,
        network: network
    })
        .then(response => {
            // navigate('/dfo-page?id=' + mainWallet);
        })
}

