import React, {useEffect, useState} from 'react';
import "../assets/scss/_token-creation.scss"
import axios from "axios";
import Web3 from "web3";
import FundraisingApprove from "../components/modals/FundraisingApprove";
import {Link, useLocation} from "react-router-dom";
import ChooseToken from "../components/fundraisingCreation/ChooseToken";
import TokenConstructor from "../components/fundraisingCreation/TokenConstructor";
import FundraisingConstructor from "../components/fundraisingCreation/FundraisingConstructor";
import LoadingModal from "../components/modals/LoadingModal";
import {useNetwork} from "wagmi";
import {factoryAddresses} from "../utils/metamaskNetworkConfigs";

const toSeconds = {
    day: 86400,
    week: 86400 * 7,
    month: 86400 * 30,
    quarter: 86400 * 90,
}

export default function FundraisingCreation(props) {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [tokenName, setTokenName] = useState('');
    const [ticker, setTicker] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const [showReport, setShowReport] = useState(false);
    const [fundraisingStep, setFundraisingStep] = useState(0);
    const [existingToken, setExistingToken] = useState(true);

    const [predefinedLabel, setPredefinedLabel] = useState("");

    const location = useLocation();
    const params = new URLSearchParams(location.search)

    const [vestingCount, setVestingCount] = useState(0);
    const [fundraisingCount, setFundraisingCount] = useState(1);
    const [ownerTokens, setOwnerTokens] = useState(0);
    const [totalMintableTokens, setTotalMintableTokens] = useState(0);
    const [totalMintableTokensInWei, setTotalMintableTokensInWei] = useState(0);
    const[showFundraisingAllocation, setShowFundraisingAllocation] = useState(false);
    const[showFundraisingApprove,setShowFundraisingApprove] = useState(false);
    const[isEnabled, setIsEnabled] = useState(false);
    const[tokenAddress, setTokenAddress] = useState('');
    const[fundraisingAddress, setFundraisingAddress] = useState('');
    const[fundraisingAmount, setFundraisingAmount] = useState(0);


    const handleChangeMintable = () => setMintable(!mintable);
    const handleChangeBurnable = () => setBurnable(!burnable);
    const handleChangePausable = () => setPausable(!pausable);

    let [tokenData, setTokenData] = useState(["", "", "", 0, false]);

    const[mintable, setMintable] = useState(false);
    const[burnable, setBurnable] = useState(false);
    const[pausable, setPausable] = useState(false);

    const { chain } = useNetwork()

    const factoryInfo = factoryAddresses[chain.network];
    const { erc20FactoryAddress } = factoryInfo;

    const[erc20TokenFactoryAddress, setErc20TokenFactoryAddress] = useState(erc20FactoryAddress);

    const [formValues, setFormValues] = useState([{ label: "",
        saleStart: new Date(),
        durationPeriod: 0,
        durationPeriodOption:"day",
        releasePeriod : 0,
        releasePeriodOption: "day",
        lockupPeriod: 0,
        lockupPeriodOption: "day",
        tge: 0,
        tokenAmount: 0}])
    const [encodedFormValues, setEncodedFormValues] = useState([])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
        const encoded =
            [formValues[i]["label"],
                Math.floor(new Date(formValues[i]["saleStart"]).getTime() / 1000),
                formValues[i]["lockupPeriod"] * toSeconds[formValues[i]["lockupPeriodOption"]],
                formValues[i]["durationPeriod"] * toSeconds[formValues[i]["durationPeriodOption"]],
                formValues[i]["releasePeriod"] * toSeconds[formValues[i]["releasePeriodOption"]],
                formValues[i]["tge"],
                Web3.utils.toWei(formValues[i]["tokenAmount"]).toString(),
            0,
            true];
        let newEncodedFormValues = [...encodedFormValues];
        newEncodedFormValues[i] = encoded;

        setEncodedFormValues(newEncodedFormValues);
        const encodedTokenData = [];
        encodedTokenData.push(tokenName, ticker, erc20TokenFactoryAddress, totalMintableTokens, true);

        setTokenData(encodedTokenData);
    }

    const [fundraisingFormValues, setFundraisingFormValues] = useState([{
        label : "",
        vestingStart : new Date(),
        cliffPeriod: 0,
        cliffPeriodOption: "day",
        vestingPeriod: 0,
        vestingPeriodOption: "day",
        releasePeriod: 0,
        releasePeriodOption: "day",
        tgePercent: 0,
        minTicketLimit: 0,
        totalCampaignLimit: 0,
        campaignSoftCap: 0,
        rate: 100,
        saleStartDate: new Date(),
        saleEndDate: new Date(),
        isPrivate: false
    }])

    const [encodedFundraisingFormValues, setEncodedFundraisingFormValues] = useState([])

    let handleChangeFundraising = (i, e) => {
        let newFundraisingFormValues = [...fundraisingFormValues];
        let newValue = e.target.value;
        if (e.target.name === "isPrivate"){
            newValue = !fundraisingFormValues[i][e.target.name];
        }
        newFundraisingFormValues[i][e.target.name] = newValue;
        setFundraisingFormValues(newFundraisingFormValues);
        const encoded =
            [fundraisingFormValues[i]["label"],
                Math.floor(new Date(fundraisingFormValues[i]["vestingStart"]).getTime() / 1000),
                fundraisingFormValues[i]["cliffPeriod"] * toSeconds[fundraisingFormValues[i]["cliffPeriodOption"]],
                fundraisingFormValues[i]["vestingPeriod"] * toSeconds[fundraisingFormValues[i]["vestingPeriodOption"]],
                fundraisingFormValues[i]["releasePeriod"] * toSeconds[fundraisingFormValues[i]["releasePeriodOption"]],
                fundraisingFormValues[i]["tgePercent"],
                Web3.utils.toWei(fundraisingFormValues[i]["totalCampaignLimit"].toString()),
                // fundraisingFormValues[i]["totalCampaignLimit"],
                Web3.utils.toWei(fundraisingFormValues[i]["campaignSoftCap"].toString()),
                fundraisingFormValues[i]["rate"],
                Math.floor(new Date(fundraisingFormValues[i]["saleStartDate"]).getTime() / 1000),
                Math.floor(new Date(fundraisingFormValues[i]["saleEndDate"]).getTime() / 1000),
                fundraisingFormValues[i]["isPrivate"],
                Web3.utils.toWei(fundraisingFormValues[i]["minTicketLimit"].toString()),
            ];
        let newEncodedFormValues = [...encodedFundraisingFormValues];
        newEncodedFormValues[i] = encoded;

        setEncodedFundraisingFormValues(newEncodedFormValues);

        if (e.target.name === "totalCampaignLimit"){
            let fundraisingTotalTokens = 0;
            newEncodedFormValues.forEach((formValues) => {
                fundraisingTotalTokens += Number(formValues[6])
            })
            console.log('FUNDRAISING TOTAL TOKENS ' + fundraisingTotalTokens)

            const mintableTokens = Number(Web3.utils.toWei(ownerTokens.toString(), "ether")) + Number(fundraisingTotalTokens);
            setTotalMintableTokens(mintableTokens);
            const encodedTokenData = [];
            encodedTokenData.push(tokenName, ticker, erc20TokenFactoryAddress, mintableTokens, true);

            setTokenData(encodedTokenData);
        }
    }

    const handleChangeTokenName = (event) => {
        setTokenName(event.target.value);
        const encodedTokenData = [];
        encodedTokenData.push(event.target.value, ticker, erc20TokenFactoryAddress, totalMintableTokens, true);

        setTokenData(encodedTokenData);
    }

    const handleChangeTicker = (event) => {
        setTicker(event.target.value);
        const encodedTokenData = [];
        encodedTokenData.push(tokenName, event.target.value, erc20TokenFactoryAddress, totalMintableTokens, true);

        setTokenData(encodedTokenData);
    }

    const handleSetOwnerTokens = (event) => {
        setOwnerTokens(event.target.value);
        let fundraisingTotalTokens = 0;
        encodedFundraisingFormValues.forEach((formValues) => {
            fundraisingTotalTokens += formValues[6]
        })

        console.log("event value " + Web3.utils.toWei(event.target.value.toString(), "ether"))
        console.log("event value " + Number(Web3.utils.toWei(event.target.value.toString(), "ether")))
        const mintableTokens = Number(Web3.utils.toWei(event.target.value.toString(), "ether") + Number(fundraisingTotalTokens));
        setTotalMintableTokens(mintableTokens);
    }

    const[approveEnabled, setApproveEnabled] = useState(false);
    const[dataForApprove, setDataForApprove] = useState([]);

    const closeApproveModal = () => {
        setShowFundraisingApprove(false)
        setIsEnabled(true);
        setShowFundraisingAllocation(true)
    }

    return (
        <>
            <FundraisingApprove show={showFundraisingApprove} approveEnabled={approveEnabled} dataForApprove={dataForApprove} fundraisingAddress={fundraisingAddress} tokenAddress={tokenAddress} amount={fundraisingAmount} close={() => closeApproveModal()}/>
            <LoadingModal show={isLoading} text="Deploying new token..."/>
            <div className='container'>
                { fundraisingStep  === 0 &&
                    <ChooseToken setTokenAddress={setTokenAddress}
                                 existingToken={existingToken}
                                 setExistingToken={setExistingToken}
                                 setFundraisingStep={setFundraisingStep}
                    />
                }
                { fundraisingStep  === 1 &&
                    <TokenConstructor setVestingCount={setVestingCount}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      setEncodedFormValues={setEncodedFormValues}
                                      ownerTokens={ownerTokens}
                                      handleChangeTicker={handleChangeTicker}
                                      handleChange={handleChange}
                                      handleChangeTokenName={handleChangeTokenName}
                                      handleSetOwnerTokens={handleSetOwnerTokens}
                                      handleChangeMintable={handleChangeMintable}
                                      handleChangeBurnable={handleChangeBurnable}
                                      handleChangePausable={handleChangePausable}
                                      setFundraisingStep={setFundraisingStep}
                                      vestingCount={vestingCount}
                                      existingToken={existingToken}
                    />
                }
                { fundraisingStep === 2 &&
                    <div className='token-creation'>
                        <FundraisingConstructor
                            fundraisingFormValues={fundraisingFormValues}
                            handleChangeFundraising={handleChangeFundraising}
                            setFundraisingFormValues={setFundraisingFormValues}
                            setFundraisingCount={setFundraisingCount}
                            setEncodedFundraisingFormValues={setEncodedFundraisingFormValues}
                        />
                        <div className="-fund-navigation">
                            <button
                                className="-button"
                                onClick={() => setFundraisingStep(1)}
                            >
                                Back
                            </button>
                            <Link to="/launch-fundraising" className='-link' state={{ tokenData: {tokenData},
                                encodedFormValues: {encodedFormValues},
                                vestingCount: {vestingCount},
                                encodedFundraisingFormValues: {encodedFundraisingFormValues},
                                fundraisingCount:{fundraisingCount},
                                mainWalletAddress: params.get("id"),
                                burnable: {burnable},
                                pausable: {pausable},
                                mintable: {mintable}
                            }}>
                                <button
                                    className="-button"
                                >
                                    Next
                                </button>
                            </Link>
                        </div>
                    </div>
                }

            </div>
        </>
    );
}
