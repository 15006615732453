import React, {useEffect, useState} from 'react';
import "../assets/scss/_components.scss";
import "../assets/scss/_curators.scss";
import "../assets/scss/_search.scss"
import "../assets/scss/_layout.scss"
import axios from "axios";
import {useAccount} from "wagmi";
import { Checkbox } from 'antd';
import MatchMassageModal from "../components/modals/MatchMassageModal";
import rolesLabels from "../json/rolesLabels";
// import Pagination from "../components/Pagination";
import {useCuratorsAccess} from "../contexts/CuratorsAccess";
import CuratorsCard from "../components/CuratorsCard";
import {useLocation} from "react-router-dom";
import { Pagination, Tag } from 'antd';
import {useAuthenticate} from "../hooks/useAuthenticate";




const params = Object.keys(rolesLabels).reduce((json, key) => {
    json[key] = false;
    return json;
}, {});
export default function Curators() {
    const location = useLocation();
    const {hasCurratorAccess} = useCuratorsAccess();
    console.log(hasCurratorAccess);
    const { address } = useAccount();
    const [filterParams, setFilterParams] = useState(params);
    const { authenticate } = useAuthenticate();
    const paramsKeys = Object.keys(params);
    // for pagination
    // const [itemsPerPage, setItemsPerPage ] = useState(hasCurratorAccess ? 12 : 8);
    const [itemsPerPage, setItemsPerPage ] = useState(8);
    const [currentPage, setCurrentPage ] = useState(1);
    const [pageCount, setPageCount ] = useState(1);

    const [curators, setCurators] = useState();
    const [newCurators, setNewCurators ] = useState({data: [], filterParams: filterParams});
    const [updateTrigger, setUpdateTrigger ] = useState(0);

    const [cachedImages, setCachedImages ] = useState([]);
    const [newCachedImages, setNewCachedImages ] = useState({});
    const [companyAddress, setCompanyAddress ] = useState('');

    const [matchMassageModal, setMatchMassageModal ] = useState(false);
    const [currentMatchCurator, setCurrentMatchCurator ] = useState("");
    const [curatorWallet, setCuratorWallet ] = useState("");


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/getDfoCompaniesByOwner', { params: { owner: address } })
            .then( response => setCompanyAddress(response.data[0]));
    }, [])
    useEffect(() => {
        getCurators(itemsPerPage, currentPage);
    }, [filterParams, itemsPerPage, currentPage])

    const handleChange = (parameter) => {
        setCurrentPage(1);
        setFilterParams({ ...filterParams, [parameter]: !filterParams[parameter] });
    };

    useEffect(() => {
        if (newCurators.filterParams === filterParams) {
            setCurators(<CuratorsCards data={newCurators.data} />);
        }
    }, [newCurators, updateTrigger])

    const handleMatchCurator = (curatorName, curatorWalletAddress) => {
        setCurrentMatchCurator(curatorName);
        setCuratorWallet(curatorWalletAddress);
        setMatchMassageModal(true);
    }

    const handleChangePageSize = (page, pageSize) => {
        setCurrentPage(page);
        if (pageSize !== itemsPerPage) {
            setItemsPerPage(pageSize)
            setCurrentPage(1)
        }
    };

    const showAll = () => {
        setFilterParams(params)
    }

    const getCurators = async (itemPerPage, currentPage) => {
        const params = {
            owner: address,
            pageNumber: currentPage,
            pageSize: itemPerPage
        };
        const queryParams = filterParams;

        for (const param in queryParams) {
            if (queryParams.hasOwnProperty(param) && queryParams[param]) {
                params[param] = queryParams[param];
            }
        }
        const path = hasCurratorAccess ? '/api/getCurators' : '/api/getPublicCurators';

        if ((Number(localStorage.getItem('expirationDate')) - new Date() < 0) && hasCurratorAccess) {
            authenticate(address)
        }
        await getCuratorsFromDb(path, queryParams, params)
    }

    const getCuratorsFromDb = async (path, queryParams, params) => {
        console.log("PARAMS in getCurators from db " + JSON.stringify(params))
        await axios.get(process.env.REACT_APP_API_ENDPOINT + path,{ params })
            .then(response => {
                const curatorsFromDb = [];
                response.data.transformedData.forEach(async (curator) => {
                    curatorsFromDb.push({
                        walletAddress: curator.walletAddress,
                        name: curator.nickName,
                        img: "",
                        aboutme : curator.aboutme,
                        linkedin: curator.linkedin,
                        facebook: curator.facebook,
                        youtube: curator.youtube,
                        twitter: curator.twitter,
                        discord: curator.discord,
                        preferredSocialMedia: curator.preferredSocialMedia,
                        roles : {
                            isFundraisingAdvisory: curator.isFundraisingAdvisory,
                            isStrategicAdvisory: curator.isStrategicAdvisory,
                            isLegalAdvisory: curator.isLegalAdvisory,
                            isTechnicalAdvisory: curator.isTechnicalAdvisory,
                            isFinancialAdvisory: curator.isFinancialAdvisory,
                            isProductAdvisory: curator.isProductAdvisory,
                            isMarketingAdvisory: curator.isMarketingAdvisory,
                            isTokenomicsAdvisory: curator.isTokenomicsAdvisory,
                            isGotoMarketStrategy: curator.isGotoMarketStrategy,
                            isGrowthStrategy: curator.isGrowthStrategy,
                            isListings: curator.isListings,
                            isLiquidity: curator.isLiquidity,
                            isInfrastructure: curator.isInfrastructure,
                            isSmartContracts: curator.isSmartContracts,
                            isNFT: curator.isNFT,
                            isMetaverse: curator.isMetaverse,
                            isGameFi: curator.isGameFi,
                            isMarketingExecution: curator.isMarketingExecution,
                            isPR: curator.isPR,
                            isContentCreation: curator.isContentCreation,
                            isTwitterKOL: curator.isTwitterKOL,
                            isYoutubeKOL: curator.isYoutubeKOL,
                            isInfluencer: curator.isInfluencer,
                            isAmbassador: curator.isAmbassador,
                            isCommunityBuilding: curator.isCommunityBuilding,
                            isCommunityManagement: curator.isCommunityManagement,
                            isDesign: curator.isDesign,
                            isUIUX: curator.isUIUX,
                            isBusinessModel: curator.isBusinessModel,
                            isPitchDeck: curator.isPitchDeck,
                            isWhitepaper: curator.isWhitepaper,
                            isHrRecruiting: curator.isHrRecruiting,
                            isDefi: curator.isDefi,
                            isAi: curator.isAi,
                            isTokenDesign: curator.isTokenDesign
                        }
                    })
                });
                // setCurators(<CuratorsCards data={curatorsFromDb} />);
                setPageCount(Math.ceil(response.data.userCount / itemsPerPage));
                setNewCurators({data: curatorsFromDb, filterParams: queryParams});
                for (let i = 0; i < curatorsFromDb.length; i++) {
                    if (cachedImages[curatorsFromDb[i].walletAddress] === undefined) {
                        axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/getUserProfileLogo',
                            {params: {walletAddress: curatorsFromDb[i].walletAddress}})
                            .then(response => {
                                curatorsFromDb[i].img = response.data.avatarLogo;
                                setNewCurators({data: curatorsFromDb, filterParams: queryParams});
                                setUpdateTrigger(i);
                                setNewCachedImages({walletAddress: curatorsFromDb[i].walletAddress, data: response.data.avatarLogo});
                                // setCurators(<CuratorsCards data={curatorsFromDb} />);
                            })
                    } else {
                        curatorsFromDb[i].img = cachedImages[curatorsFromDb[i].walletAddress];
                        setCurators(<CuratorsCards data={curatorsFromDb} />);
                    }
                }

            })
    }

    const CuratorsCards = ({data}) => {
        return (
            <div className="-container">
                {data.map((curator, key) =>
                    <CuratorsCard curator={curator} key={key} hasCurratorAccess={hasCurratorAccess} handleMatchCurator={handleMatchCurator}/> )}
            </div>
        );
    }

    return (
        <div className="main-container">
            <MatchMassageModal show={matchMassageModal} close={() => setMatchMassageModal(false)} curatorWallet={curatorWallet} currentMatchCurator={currentMatchCurator}/>
            {hasCurratorAccess && <>
                <div className="socials">Get to know our Curators! </div>

                <div className="curator-text">Search by Tags</div>
                </>
            }
            <div className='curators'>
                {hasCurratorAccess &&
                    <>
                        <div className="-container">
                            <div className="-filter">
                                {paramsKeys.map( (parameter, key) =>
                                    <Tag className="-tag"                                        key={key}
                                        color={filterParams[parameter] ? 'geekblue' : 'default'}
                                        onClick={() => handleChange(parameter)}
                                    >
                                        {rolesLabels[parameter]}
                                    </Tag>
                                )}
                                <Tag className="-tag" color="geekblue" onClick={() => showAll()}>Show all</Tag>
                            </div>
                        </div>
                    </>
                }
                {curators}
                {!hasCurratorAccess && location.pathname !== '/' &&
                    <div>
                        <br/>
                        <br/>
                        <br/>
                        <h3>In order to see the full list of curators your project needs to have at least "Explorer" listing tier.</h3>
                    </div>
                }
            </div>
            {pageCount > 1 &&
                <div className="pagination">
                    <Pagination
                        current={currentPage}
                        total={pageCount * itemsPerPage}
                        pageSize={itemsPerPage}
                        pageSizeOptions={[8,12,16]}
                        onChange={(page, pageSize) => handleChangePageSize(page, pageSize)}
                    />
                </div>
            }
        </div>
    );
}
