import React, {useState} from 'react';

export const useValidFile = (initialState) => {
    const [value, setValue] = useState(initialState);
    const [file, setFile] = useState(initialState);
    const [isUploaded, setIsUploaded] = useState(false);

    const onChange = e => {
        setValue(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
        setIsUploaded(true);
        validate(e.target.files[0])
    }

    const trigger = () => validate(value);

    const [isValid, setIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const validate = (val) => {
        const maxSize = 5 * 1024 * 1024; // 5 mb
        if (val !== null) {
            if (val.size > maxSize) {
                setIsValid(false);
                setErrorMessage('file is too large');
            } else {
                setIsValid(true);
                setErrorMessage('');
            }
        } else {
            setIsValid(false);
            setErrorMessage('Company logo must not be empty');
        }
    }

    return {value, file, isUploaded, isValid, errorMessage, onChange, trigger};
}