// src/WhitelistedAddresses.js
import React, { useState, useEffect } from 'react';
import '../../assets/scss/_add-beneficiar-modal.scss';
import { Input, Button } from 'antd';
import { isAddress } from 'ethers';

export default function WhitelistedAddresses({setValidWhitelistedAddress, trigger}) {
    const [walletAddresses, setWalletAddresses] = useState(['']);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const err = validateAllAddresses(walletAddresses)
        const valid = walletAddresses.length === 0 ? false : err.every(error => error === '');
        setErrors(err);
        setValidWhitelistedAddress(valid);
    }, [trigger]);

    const validateAllAddresses = (addresses) => {
        return addresses.map(address => isAddress(address) ? '' : 'Invalid address');
    };

    const handleInputChange = (index, event) => {
        const values = [...walletAddresses];
        values[index] = event.target.value;
        setWalletAddresses(values);

        const errorMessages = [...errors];
        errorMessages[index] = isAddress(event.target.value) ? '' : 'Invalid address';
        setErrors(errorMessages);
    };

    const handleAddInput = () => {
        setErrors(validateAllAddresses(walletAddresses));
        const newAddresses = [...walletAddresses, ''];
        setWalletAddresses(newAddresses);
    };

    const handleRemoveInput = (index) => {
        const values = [...walletAddresses];
        values.splice(index, 1);
        setWalletAddresses(values);
        setErrors(validateAllAddresses(values));
    };

    return (
        <div>
            {walletAddresses.map((address, index) => (
                <>
                    <div className="add-beneficiar whitelisted" key={index}>
                        <Input
                            type="text"
                            value={address}
                            onChange={(event) => handleInputChange(index, event)}
                            placeholder="enter address"
                        />
                        <Button className="remove-btn" danger onClick={() => handleRemoveInput(index)}>&#x2715;</Button>
                    </div>
                    {errors[index] && <div className="-text error">{errors[index]}</div>}
                </>
            ))}
            <br/>
            <Button onClick={handleAddInput} type="primary">Add address</Button>
        </div>
    );
};
