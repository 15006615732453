import * as ethers from "ethers";
import Fundraising from "../abis/FundRaising.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import ERC20Token from "../abis/ERC20Token.json";

const handleAddFundraising = async (vestingPlanParams, investmentTokens, referralAddress, projectName, mintingToken, whitelistedAddresses) => {

    console.log('INVESTMENT TOKENS ' + investmentTokens)
    console.log('VESTING PARAMS ' + JSON.stringify(vestingPlanParams))

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const saleUUIDPromise = new Promise((resolve, reject) => {
            fundraisingContract.on("FundraisingCreated", (arg1, arg2, arg3, event) => {
                console.log("Event received:", arg1, arg2, arg3);
                console.log("Event:", event);
                resolve(arg2);
            });
        });

        console.log('VESTING PLANS ' + JSON.stringify(vestingPlanParams))

        const tx = await fundraisingContract.createFundraising(vestingPlanParams,
            investmentTokens,
            "0x217d52483A2f118753F2f557Ed4f91236A635A5d",
            projectName,
            mintingToken,
            whitelistedAddresses,
            { value: "0" });

        await tx.wait();

        return await saleUUIDPromise;

        // tokenFactoryContract.off("ContractCreated", eventHandler); - switch off only attached event
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

export async function invest(amount, investmentTokenAddress, saleUUID, decimals) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const totalTokenAmountInWei = (amount * (10 ** Number(decimals))).toLocaleString()
        const totalTokenAmountInWeiNormalized = totalTokenAmountInWei.replace(/,/g, '')

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.invest(totalTokenAmountInWeiNormalized, investmentTokenAddress, saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

export async function closeCampaign(saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.closeCampaign(saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
};

export async function claimTokens(saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.claimTokens(saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
        return error;
    }
};

export async function reclaimTokens(saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.reclaimTokens(saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
};

export async function getWhitelistedTokens(saleIdx) {
    const erc20Addresses = []

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        erc20Addresses.push(await fundraisingContract.getWhitelistedTokens(saleIdx));

        return erc20Addresses;

    } catch (error) {
        console.error('Error while reading from smart contract:', error);
    }
}

export async function addWhitelistAddress(whitelistedAddresses, saleUUID) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        const tx = await fundraisingContract.addWhitelistAddress(whitelistedAddresses, saleUUID);

        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
};

export async function getIndividualBalanceForToken (saleIdx, erc20Address) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.getIndividualBalanceForToken(saleIdx, erc20Address);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getIndividualBalances (saleIdx) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.individualBalances(saleIdx, signer);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function computeReleasableAmount (saleIdx) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.computeReleasableAmount(saleIdx);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getReleasedTokens (saleIdx, erc20Address) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.released(saleIdx, erc20Address);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getChainlinkDataFeedLatestAnswer (erc20Address) {

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, signer);

        return await fundraisingContract.getChainlinkDataFeedLatestAnswer(erc20Address);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getProjectsFundraisings(projectName) {

    const erc20Addresses = []

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        erc20Addresses.push(...await queryFundraisingsCreated(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, signer, projectName));

        return erc20Addresses;

    } catch (error) {
        console.error('Error while reading from smart contract:', error);
    }
}

async function queryFundraisingsCreated(factoryContractAddress, signer, projectName){
    let fundraisings = []
    const tokenFactoryContract = new ethers.Contract(factoryContractAddress, Fundraising, signer);
    const specificFilter = tokenFactoryContract.filters.FundraisingCreated(signer, null, null);

    const matchedEvents = await tokenFactoryContract.queryFilter(specificFilter, null, "latest")
    for(const event of matchedEvents){
        const contractAddress = event.args[0];
        const fundraisingInstance = event.args[1];
        const projectNameFromChain = event.args[2];
        if (projectNameFromChain === projectName){
            fundraisings.push(fundraisingInstance)
        }
    }
    return fundraisings;
}

export async function getFundraisingInstance (fundraisingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, provider);

        console.log('idx ' + fundraisingIdx)
        return await fundraisingContract.getFundraisingInstance(fundraisingIdx);

    } catch (error) {
        console.error('Error reading from smart contract:', error);
    }
}

export async function getVestingPlan (fundraisingIdx) {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();
        const fundraisingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, Fundraising, provider);

        console.log('idx ' + fundraisingIdx)
        return await fundraisingContract.getVestingPlan(fundraisingIdx);

    } catch (error) {
        console.error('Error reading from smart contract:', error);
    }
}

export default handleAddFundraising;
