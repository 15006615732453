import React from 'react';
import "../../assets/scss/_slider.scss";

// Import images
import metad from "../../assets/images/home/partners/3metad.svg";
import arthera from "../../assets/images/home/partners/arthera.svg";
import Blockchaincuties from "../../assets/images/home/partners/Blockchaincuties.svg";
import chainback from "../../assets/images/home/partners/chainback.svg";
import Coldstack from "../../assets/images/home/partners/Coldstack.svg";
import consensys from "../../assets/images/home/partners/consensys_scale.svg";
import kagedo from "../../assets/images/home/partners/kagedo.svg";
import leapfi from "../../assets/images/home/partners/leapfi.svg";
import Linea from "../../assets/images/home/partners/Linea.svg";
import Paceventures from "../../assets/images/home/partners/Paceventures.svg";
import router_protocol from "../../assets/images/home/partners/router_protocol.svg";
import salus from "../../assets/images/home/partners/salus.svg";
import scroll from "../../assets/images/home/partners/scroll.svg";
import web3global from "../../assets/images/home/partners/web3global.svg";
import winee3 from "../../assets/images/home/partners/winee3.svg";
import zetachain from "../../assets/images/home/partners/zetachain.svg";

const images = [
    arthera, metad, Blockchaincuties, chainback, Coldstack, consensys,
    kagedo, leapfi, Linea, Paceventures, router_protocol, salus,
    scroll, web3global, winee3, zetachain
];

export default function Partners() {
    return (
        <div className="partners-container">
            <div className="partners-marquee">
                <div className="partners-marquee-inner">
                    {images.map((image, index) => (
                        <img key={index} src={image} alt={`partner-${index}`} className="partner-logo" />
                    ))}
                    {images.map((image, index) => (
                        <img key={index + images.length} src={image} alt={`partner-${index}`} className="partner-logo" />
                    ))}
                </div>
            </div>
        </div>
    );
}
