import React, {useState} from 'react';
import "../assets/scss/_registration.scss"
import "../assets/scss/_components.scss"
import LoadingModal from "../components/modals/LoadingModal";
import {useAccount, useNetwork} from 'wagmi'
import axios from "axios";

export default function ThankYouWindow() {

    const [Loading, setLoading] = useState(false);
    const[email, setEmail] = useState("");
    const [errorText, setErrorText] = useState("")

    const handleChangeEmail = e => {
        setEmail(e.target.value)
    }

    const registerEmail = async() => {
        if (validateEmail(email) !== null){
            setErrorText("")
            await axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/saveEmail', {
                email: email
            })
                .then(response => {
                    window.location.replace("https://drive.google.com/uc?export=download&id=1QqOPb8s6iZwdCLnuhrRhbWLpMg3Ve4d_")
                })
        } else setErrorText("Invalid email address")
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <div className='registration-wrapper'>
            <div>
                <div className="-title">Learn the Best Ecosystem BD Tactics for Early-Stage Projects<br /></div>
                <div>You’re almost there fren. Enter your email address to access the guide and slash BD time in half! We won’t send spam, just maybe a monthly newsletter for founders once we stop to catch our breath.<br /></div>
            </div>
            <div>
                <input
                    className='-input'
                    placeholder='Enter email address'
                    value={email}
                    onChange={e => handleChangeEmail(e)}
                />
            </div>
            <div className='-valid-msg'>{errorText}</div>
            <div>
                <button className='registration-button wide' onClick={registerEmail}>Read the guide</button>
            </div>
            <LoadingModal  show={Loading} text='Registration in process...' />
        </div>
    );
}
