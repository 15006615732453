export const SaleCampaignStatus = {
    // Current date > Sale start date
    PRE_SALE: "Pre-sale",
    // Current date < Sale start date < Sale end date
    ACTIVE: "Active",
    // Current date < Sale start date > Sale end date && totalFundraised > campaignSoftCap
    SUCCESS: "Success",
    // Current date < Sale start date > Sale end date && totalFundraised < campaignSoftCap
    FAILED: "Failed",
};

export function getSaleCampaignStatus(saleStart, saleEnds, tokensAllocated) {

    const currentDate = new Date().getTime() / 1000;

    if (currentDate < saleStart) {
        return SaleCampaignStatus.PRE_SALE;
    } else if (currentDate >= saleEnds) {
        return SaleCampaignStatus.SUCCESS;
    } else if (currentDate >= saleStart && currentDate <= saleEnds) {
        return SaleCampaignStatus.ACTIVE;
    } else {
        return SaleCampaignStatus.FAILED;
    }
}
