import React, {useEffect, useState} from 'react';
import {useValidFile} from "../../hooks/useValidFile";
import {useAuthenticate} from "../../hooks/useAuthenticate";
import {useAccount} from "wagmi";
import {toast} from "react-toastify";
import Aim from "../../assets/icons/userSettings/aim.svg"
import Avatar from "../../assets/icons/userSettings/avatar.svg"
import Led from "../../assets/icons/userSettings/led.svg"
import Marker from "../../assets/icons/userSettings/marker.svg"
import '../../assets/scss/_cabinet-settings.scss'
import {Input, Button, Tag, Radio} from 'antd';


import convertToBase64 from "../../utils/FileConverter";
import axios from "axios";

export default function ProjectSettings(props) {

    const fileDrop = useValidFile(null);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const { authenticate } = useAuthenticate();
    const { address } = useAccount();
    const { TextArea } = Input;

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setCompanyData((prev) => {
            return {
                ...prev,   // Spread Operator
                [name]: value
            }
        })
    }
    const fixInputLink = event => {
        let value = event.target.value;
        value.replace(/[:\/]/g, '');
        if (!value.match(/^(http|https|htt|htp):\/\//)) value = 'https://' + value;
        value = value.replace(/^((?!https:\/\/)[^:]+)?:\/\//, 'https://');
        let name = event.target.name;
        setCompanyData((prev) => {
            return {
                ...prev,   // Spread Operator
                [name]: value
            }
        })
    }

    const [companyData, setCompanyData] = useState({
        twitter: props.twitter,
        telegram: props.telegram,
        discord: props.discord,
        youtube: props.youtube,
        facebook: props.facebook,
        about: props.about,
        documentation: props.documentation,
        linkedin: props.linkedIn,
        websiteUrl: props.websiteUrl,
        elevatorPitch: props.elevatorPitch
    })

    useEffect(() => {
        setCompanyData({
            twitter: props.twitter,
            telegram: props.telegram,
            discord: props.discord,
            youtube: props.youtube,
            facebook: props.facebook,
            about: props.about,
            documentation: props.documentation,
            elevatorPitch: props.elevatorPitch,
            linkedin: props.linkedIn,
            websiteUrl: props.websiteUrl,
        })
    },[
        props.twitter,
        props.telegram,
        props.discord,
        props.youtube,
        props.facebook,
        props.about,
        props.documentation,
        props.elevatorPitch,
        props.linkedIn,
        props.websiteUrl,
    ])

    const submit = async (e) => {
        // Check if authentication is needed or if the token has expired
        if (shouldAuthenticate()) {
            toast.error("Unauthorized. Please sign in, and repeat the action.")
            await authenticate(address);
        }

        e.preventDefault();

        // Get the base64 representation of the image
        const base64 = await getImageBase64();

        // Update company data
        await updateCompanyData(base64);

        // Show success message and update project data
        toast.success("Company data updated.");
        props.updatePrjectData();
        props.close();
    };

    const shouldAuthenticate = () => {
        const expirationDate = Number(localStorage.getItem('expirationDate'));
        return expirationDate - new Date() < 0;
    };

    const getImageBase64 = async () => {
        if (fileDrop.value === null) {
            return props.imgUrl;
        } else {
            return convertToBase64(fileDrop.value);
        }
    };

    const updateCompanyData = async (base64) => {
        await axios.post(apiUrl + '/api/updateCompany', {
            companyName: props.companyName,
            logo: base64,
            twitter: companyData.twitter,
            telegram: companyData.telegram,
            discord: companyData.discord,
            youtube: companyData.youtube,
            facebook: companyData.facebook,
            about: companyData.about,
            documentation: companyData.documentation,
            elevatorPitch: companyData.elevatorPitch,
            linkedin: companyData.linkedin,
            websiteUrl: companyData.websiteUrl
        });
    };

    return (
        <div>
            <div className='settings-page'>
                <div className="-grid">
                    <div>
                        <div className='-section'>
                            <img src={Avatar} className='-img' alt=""/>
                            <div className='-text'>Logo</div>
                        </div>
                    </div>
                    <div className="-input-grid">
                        <div className='-text'></div>
                        <label htmlFor='choose-file' className='-dropdown'>
                            {!fileDrop.isUploaded ?
                                <>
                                    <div className='-text bold'>Choose company logo here or <span
                                        className='-accent'>browse</span></div>
                                    <div className='-text'>.PNG on transparent background<br/></div>
                                </>
                                :
                                <img src={fileDrop.file} className='-img' alt=""/>
                            }
                            <input type="file" className='-input' id='choose-file'
                                   accept=".jpg, .jpeg, .png, .pdf, .svg" onChange={e => fileDrop.onChange(e)}/>
                        </label>
                        {!fileDrop.isValid && <div className='-valid-msg'>{fileDrop.errorMessage}</div>}
                    </div>
                </div>
                <div className="-grid">
                    <div>
                        <div className='-section'>
                            <img src={Marker} className='-img' alt=""/>
                            <div className='-text'>Description</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        <div className='-input-grid'>
                            <div className='-text xs'>Elevator pitch (max 50 characters)</div>
                            <TextArea
                                type="text"
                                name="elevatorPitch"
                                onChange={handleChange}
                                value={companyData.elevatorPitch}
                                maxLength="50"
                            />
                        </div>
                        <div className='-input-grid'>
                            <div className='-text xs'>About <br /> (max 500 characters)</div>
                            <TextArea
                                type="text"
                                name="about"
                                onChange={handleChange}
                                value={companyData.about}
                                maxLength="500"
                            />
                        </div>
                    </div>
                </div>
                <div className="-grid">
                    <div>
                        <div className='-section'>
                            <img src={Led} className='-img' alt=""/>
                            <div className='-text'>Links</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        <div className='-input-grid'>
                            <div className='-text xs'>Documentation</div>
                            <Input
                                type="text"
                                name="documentation"
                                onChange={handleChange}
                                value={companyData.documentation}
                            />
                        </div>

                        <div className='-input-grid'>
                            <div className='-text xs'>Website URL</div>
                            <Input
                                type="text"
                                name="websiteUrl"
                                onChange={handleChange}
                                onBlur={fixInputLink}
                                value={companyData.websiteUrl}
                            />
                        </div>
                    </div>
                </div>
                <div className="-grid disable-border">
                    <div>
                        <div className='-section'>
                            <img src={Aim} className='-img' alt=""/>
                            <div className='-text'>Social Media</div>
                        </div>
                    </div>
                    <div className="-input-wrapper">
                        <div className='-input-grid'>
                            <div className='-text'>LinkedIn</div>
                            <Input
                                type="text"
                                name="linkedin"
                                onChange={handleChange}
                                value={companyData.linkedin}
                            />
                        </div>
                        <div className='-input-grid'>
                            <div className='-text'>Twitter</div>
                            <Input
                                type="text"
                                name="twitter"
                                onChange={handleChange}
                                value={companyData.twitter}
                            />
                        </div>
                        <div className='-input-grid'>
                            <div className='-text'>Telegram</div>
                            <Input
                                type="text"
                                name="telegram"
                                onChange={handleChange}
                                value={companyData.telegram}
                            />
                        </div>
                        <div className='-input-grid'>
                            <div className='-text'>Discord</div>
                            <Input
                                type="text"
                                name="discord"
                                onChange={handleChange}
                                value={companyData.discord}
                            />
                        </div>
                        <div className='-input-grid'>
                            <div className='-text'>Youtube</div>
                            <Input
                                type="text"
                                name="youtube"
                                onChange={handleChange}
                                value={companyData.youtube}
                            />
                        </div>
                        <div className='-input-grid'>
                            <div className='-text'>Facebook</div>
                            <Input
                                type="text"
                                name="facebook"
                                onChange={handleChange}
                                value={companyData.facebook}
                            />
                        </div>
                    </div>
                </div>

                <div className="-left">
                    <Button type="primary" onClick={submit}>Save project settings</Button>
                </div>
            </div>
        </div>
    );
}
