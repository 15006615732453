import React, {useState} from 'react';
import "../assets/scss/_components.scss"
import Button from "@mui/material/Button";
import ArrowDown from "../assets/icons/arrow-down.svg";
import {chainIcons} from "../utils/chainIcons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckMark from "../assets/icons/header/checkMark.svg";
import Divider from "@mui/material/Divider";
import Arthera from "../assets/icons/header/arthera.svg";
import Zetachain from "../assets/icons/header/zetachain.svg";
import Scroll from "../assets/icons/header/scroll.svg";
import {changeNetwork} from "../utils/metamaskNetworkConfigs";
import {useNetwork, useSwitchNetwork} from "wagmi";

const ChainSelector = () => {
    const { chain } = useNetwork()
    const { chains, error, isLoading, pendingChainId, switchNetwork } =
        useSwitchNetwork()

    const [anchorElNetworkSelect, setAnchorElNetworkSelect] = useState(null);

    const handleNetworkSwitch = async (networkName) => {
        await changeNetwork({ networkName });
    };

    const openNetworkSelect = Boolean(anchorElNetworkSelect);
    const handleClickNetworkSelect = (event) => setAnchorElNetworkSelect(event.currentTarget);
    const handleCloseNetworkSelect = () => setAnchorElNetworkSelect(null);
    const handleChangeNetwork = x => {
        handleCloseNetworkSelect();
        switchNetwork?.(x);
    }

    return <div className="-desktop">
        <Button
            className='-network-select'
            disableRipple
            endIcon={<img alt='' src={ArrowDown}/>}
            sx={{textTransform: "none"}}
            id="basic-button"
            aria-controls={openNetworkSelect ? 'basic-menu' : undefined}
            aria-expanded={openNetworkSelect ? 'true' : undefined}
            style={{backgroundColor: 'transparent'}}
            onClick={handleClickNetworkSelect}
        >
            <img src={chainIcons[chain.name]} alt="" />
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorElNetworkSelect}
            disableScrollLock={true}
            open={openNetworkSelect}
            onClose={handleCloseNetworkSelect}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {chains.map((x) => (
                <MenuItem
                    classes='-network-select'
                    disabled={!switchNetwork}
                    key={x.id}
                    onClick={ x.id === chain?.id ? handleCloseNetworkSelect : () => handleChangeNetwork(x.id)}
                >
                    <ListItemIcon>
                        <img src={chainIcons[x.name]} alt="" />
                    </ListItemIcon>
                    <ListItemText>{x.name} {isLoading && pendingChainId === x.id && ' (switching)'}</ListItemText>
                    { x.id === chain?.id &&
                    <ListItemIcon variant="body2" color="text.secondary">
                        <img src={CheckMark} alt=""/>
                    </ListItemIcon>
                    }

                </MenuItem>
            ))}
            <Divider />
            <div className='menu-text'>Testnets:</div>
            <MenuItem
                classes='-network-select'
                disabled={!switchNetwork}
                onClick={ () => handleNetworkSwitch("arthera")}
            >
                <ListItemIcon>
                    <img src={Arthera} alt="" />
                </ListItemIcon>
                <ListItemText>Arthera </ListItemText>
                { chain.name === "Chain 10243" &&
                <ListItemIcon variant="body2" color="text.secondary">
                    <img src={CheckMark} alt=""/>
                </ListItemIcon>
                }
            </MenuItem>
            <MenuItem
                classes='-network-select'
                disabled={!switchNetwork}
                onClick={ () => handleNetworkSwitch("zetachain")}
            >
                <ListItemIcon>
                    <img src={Zetachain} alt="" />
                </ListItemIcon>
                <ListItemText>Zetachain </ListItemText>

                {chain.name === "Chain 7001" &&
                <ListItemIcon variant="body2" color="text.secondary">
                    <img src={CheckMark} alt=""/>
                </ListItemIcon>
                }
            </MenuItem>
            <MenuItem
                classes='-network-select'
                disabled={!switchNetwork}
                onClick={ () => handleNetworkSwitch("scroll")}
            >
                <ListItemIcon>
                    <img src={Scroll} alt="" />
                </ListItemIcon>
                <ListItemText>Scroll </ListItemText>
                { chain.name === "Chain 534351" &&
                <ListItemIcon variant="body2" color="text.secondary">
                    <img src={CheckMark} alt=""/>
                </ListItemIcon>
                }
            </MenuItem>
        </Menu>
    </div>
}

export default ChainSelector;
