const deleteIndexedDB = async () => {
    try {
        const db = await indexedDB.open('WALLET_CONNECT_V2_INDEXED_DB');

        if (db) {
            db.onerror = (event) => {
                console.error('Error opening IndexedDB:', event.target.error);
            };

            db.onsuccess = async (event) => {
                const database = event.target.result;
                if (database) {
                    const objectStoreNames = database.objectStoreNames;
                    console.log('Number of object stores:', objectStoreNames.length);
                    if (objectStoreNames.length === 0) {

                            const request = window.indexedDB.open("WALLET_CONNECT_V2_INDEXED_DB", 2);
                            request.onupgradeneeded = function(event) {
                                const db = event.target.result;
                                const objectStore = db.createObjectStore("keyvaluestorage");
                                window.location.reload()

                            };
                            request.onsuccess = function(event) {
                                const db = event.target.result;
                                window.location.reload()
                            };
                            request.onerror = function(event) {
                                console.error("Failed to open database", event.target.error);
                            };
                    }
                } else {
                    console.warn('IndexedDB does not exist');
                }
            };
        } else {
            console.warn('IndexedDB does not exist');
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
};

export default deleteIndexedDB;
