import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import "../../assets/scss/_modal.scss"
import "../../assets/scss/_balance-scheet.scss"

import {useAccount, useContractEvent, useContractWrite, usePrepareContractWrite} from "wagmi";
import ERC20Token from "../../abis/ERC20Token.json";
import EquityFundraising from "../../abis/EquityFundraising.json";
import Web3 from "web3";
import * as ethers from "ethers";
import ListItem from "../ListItem";
import axios from "axios";
import {toast} from "react-toastify";

const customStyles = {
    content: {
        width: 800,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '5px',
        marginRight: '-50%',
        border: '1px solid #1A1A1A',
        transform: 'translate(-50%, -50%)',
    },
};


export default function FundraisingApprove(props) {

    const {address} = useAccount();
    const [fundraisingData, setFundraisingData] = useState([]);

    const[fundraisingAddress, setFundraisingAddress] = useState("");
    const[amount, setAmount] = useState(0);

    const[approveEnabled, setApproveEnabled] = useState(false);
    const[allocationEnabled, setAllocationEnabled] = useState(false);

    useEffect(() => {

        const fundraisingData = [];
        for(const fundraising of props.dataForApprove){

            fundraisingData.push(

                <div className='dfo-data'>
                    <div className='-container'>
                        <div className='-link'>
                            <ListItem title='Fundraising address' value={fundraising.fundraisingAddress} />
                            <ListItem title='Amount to lock in contract' value={fundraising.amount} />
                        </div>
                        <div className='fundraising-buttons'>
                            <button className='-button' onClick={handleApproveTokens(fundraising.fundraisingAddress, fundraising.amount)}>Lock tokens in contract</button>
                        </div>
                    </div>
                </div>
            )
        }
        setFundraisingData(fundraisingData);

    },[props.approveEnabled, props.dataForApprove])

    const handleApproveTokens = (fundraisingAddress, amount) => () => {
        setFundraisingAddress(fundraisingAddress)
        setAmount(Web3.utils.toWei(amount, "ether"));
        setApproveEnabled(true);
    }

    const {config} = usePrepareContractWrite({
        address: props.tokenAddress,
        abi: ERC20Token,
        functionName: 'approve',
        enabled: approveEnabled,
        args: [fundraisingAddress, amount],
        onError(error) {
            toast.error(error.reason)
        },
    })

    const {write: approve} = useContractWrite(config)

    useContractEvent({
        address: props.tokenAddress,
        abi: ERC20Token,
        eventName: 'Approval',
        listener(owner, spender, value) {
            if (owner === address) {
                setAllocationEnabled(true);
            }
        }
    })

    const sendFunds = async (e) => {
        approve();
    };

    const handleLockFunds = async (e) => {
        e.preventDefault();
        lockFunds();
    };

    const {config: configLockFunds} = usePrepareContractWrite({
        address: fundraisingAddress,
        abi: EquityFundraising,
        functionName: 'lockFundsInCampaign',
        args: [props.tokenAddress],
        enabled: allocationEnabled,
        onError(error) {
            toast.error(error.reason)
        },
    })

    const {write: lockFunds} = useContractWrite(configLockFunds)

    useContractEvent({
        address: fundraisingAddress,
        abi: EquityFundraising,
        eventName: 'FundsLocked',
        listener(mintingErc20TokenAddress) {
            addMintingErc20TokenAddress(mintingErc20TokenAddress, fundraisingAddress);
            updateTokenData(mintingErc20TokenAddress, fundraisingAddress);
        }
    })

    async function addMintingErc20TokenAddress(mintingErc20TokenAddress, fundraisingAddress) {
        await axios.put(process.env.REACT_APP_API_ENDPOINT + '/api/addMintingErc20TokenAddress', {
            fundraisingAddress: fundraisingAddress,
            erc20TokenAddress: mintingErc20TokenAddress
        })
            .then(response => {

            })
    }

    const[approvedFundraisings, setApprovedFundraisings] = useState([]);

    async function updateTokenData(mintingErc20TokenAddress, fundraisingAddress) {
        await axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/updateTokenData', {
            fundraisingAddress: fundraisingAddress,
            erc20TokenAddress: mintingErc20TokenAddress
        })
            .then(response => {
                setApproveEnabled(false);
                setAllocationEnabled(false);

                const newApprovedFundraisings = approvedFundraisings;
                newApprovedFundraisings.push(fundraisingAddress);
                setApprovedFundraisings(newApprovedFundraisings);

                const newFundraisingData = []
                for(const fundraising of props.dataForApprove){
                    const lockedFunds = false;
                    newFundraisingData.push(
                        <div className='dfo-data'>
                            <div className='-container'>
                                <div className='-link'>
                                    <ListItem title='Fundraising label' value={fundraising.label} />
                                    <ListItem title='Fundraising address' value={fundraising.fundraisingAddress} />
                                    <ListItem title='Amount to lock in contract' value={fundraising.amount} />
                                </div>
                                {!newApprovedFundraisings.some(v => (v === fundraising.fundraisingAddress)) &&
                                    <div className='fundraising-buttons'>
                                        <button className='-button' onClick={handleApproveTokens(fundraising.fundraisingAddress, fundraising.amount)}>Lock tokens in contract</button>
                                    </div>
                                }
                                {newApprovedFundraisings.some(v => (v === fundraising.fundraisingAddress)) &&
                                <div className='fundraising-buttons'>
                                    <button className='-button'>Funds locked</button>
                                </div>
                                }

                            </div>
                        </div>
                    )
                }
                setFundraisingData(newFundraisingData);
            })
    }

    return (
        <div>
            <Modal
                isOpen={props.show}
                onRequestClose={props.close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {!approveEnabled &&
                    <>{fundraisingData}</>
                }

                {approveEnabled && !allocationEnabled &&
                    <div className='modal-content'>
                        <div className='-title'>Approving funds for fundraising contract</div>
                        <button className='-button' onClick={sendFunds}>Approve</button>
                    </div>
                }
                {approveEnabled && allocationEnabled &&
                <div className='modal-content'>
                    <div className='-title'>Locking funds in fundraising contract</div>
                    <button className='-button' onClick={handleLockFunds}>Lock funds</button>
                </div>
                }
            </Modal>
        </div>
    );
}
