import React, {useState} from "react";
import rolesLabels from "../../json/rolesLabels";
import { Tag } from 'antd';

export default function Skills(data) {
    const allRolesStates = Object.keys(data.roles);
    let userRoles = []
    for(let i = 0; i < allRolesStates.length; i++) {
        if (data.roles[allRolesStates[i]]) userRoles.push(rolesLabels[allRolesStates[i]])
    }
    let tooltipRoles = userRoles.slice(6);
    return (
        <>
            <div className="skills-title">Skills</div>
            <div className="expertise">
                {userRoles.map((role, key) =>
                    <Tag className='-role' key={key}>{role}</Tag>
                )}
            </div>
            {/*{ userRoles.length <= 7 ?*/}
            {/*    <div className="expertise">*/}

            {/*        {userRoles.map((role, key) =>*/}
            {/*            <Tag className='-role' key={key}>{role}</Tag>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*    :*/}
            {/*    <div className="expertise">*/}
            {/*        {userRoles.map((role, key) =>*/}
            {/*            (key < 6) && <Tag>{role}</Tag>*/}
            {/*        )}*/}
            {/*        <LightTooltip enterTouchDelay={0}  title={tooltipRoles.map((role, key) =>*/}
            {/*            (<div className='-tooltip'*/}
            {/*                  style={{ fontSize: "12px", marginBottom: "5px", fontWeight: "500", fontFamily: "Work sans, serif" }}*/}
            {/*                  key={key}>{role}</div>))}*/}
            {/*        placement="bottom-start">*/}
            {/*         /!*<div className='-role btn' onClick={handleToggle}>{showAllRoles ? "hide roles" : "show more"}</div>*!/*/}
            {/*            <Tag className='-role'>show more</Tag>*/}
            {/*        </LightTooltip>*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    );
}