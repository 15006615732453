
const rolesLabels = {
    isFundraisingAdvisory: "Fundraising advisory",
    isStrategicAdvisory: "Strategic advisory",
    isLegalAdvisory: "Legal advisory",
    isTechnicalAdvisory: "Technical advisory",
    isFinancialAdvisory: "Financial advisory",
    isProductAdvisory: "Product advisory",
    isMarketingAdvisory: "Marketing advisory",
    isTokenomicsAdvisory: "Tokenomics advisory",
    isGotoMarketStrategy: "Go-to-market strategy",
    isGrowthStrategy: "Growth strategy",
    isListings: "Listings",
    isLiquidity: "Liquidity",
    isInfrastructure: "Infrastructure",
    isSmartContracts: "Smart contracts",
    isNFT: "NFT",
    isMetaverse: "Metaverse",
    isGameFi: "GameFi",
    isMarketingExecution: "Marketing execution",
    isPR: "PR",
    isContentCreation: "Content creation",
    isTwitterKOL: "Twitter KOL",
    isYoutubeKOL: "YouTube KOL",
    isInfluencer: "Influencer",
    isAmbassador: "Ambassador",
    isCommunityBuilding: "Community building",
    isCommunityManagement: "Community management",
    isDesign: "Design",
    isUIUX: "UI/UX",
    isBusinessModel: "Business model",
    isPitchDeck: "Pitch deck",
    isWhitepaper: "Whitepaper",
    isHrRecruiting: "HR / Recruiting",
    isDefi: "DeFi",
    isAi: "AI"
};
export default rolesLabels;