import React from 'react';
import "../../assets/scss/_user.scss";

import DiscordPref from "../../assets/icons/curators/discord_special.svg";
import Discord from "../../assets/icons/curators/discord.svg";
import Linkedin from "../../assets/icons/curators/linkedin.svg";
import Twitter from "../../assets/icons/curators/twitter.svg";
import Youtube from "../../assets/icons/curators/youtube.svg";
import Facebook from "../../assets/icons/curators/facebook.svg";

export default  function Avatar({name, img, socials, disableName=false}) {
    return (
        <div className="user">
            {img === "" ?
                    <div className="lds-dual-ring"></div>
                    :
                    <img className='-image' alt='' src={img}/>
            }
            {!disableName && <div className='-name'>{name}</div>}
            <div className="-social-container">
                {socials.discord !== '' && socials.discord !== undefined &&
                    <a href={socials.discord} target="_blank" rel="noopener noreferrer">
                        <img src={socials.preferredSocialMedia === 'discord'?  DiscordPref : Discord} alt=""/>
                    </a>}
                {socials.linkedin !== '' && socials.linkedin !== undefined &&
                    <a href={socials.linkedin} target="_blank" rel="noopener noreferrer">
                        <img src={Linkedin} alt=""/>
                    </a>                    }
                {socials.twitter !== '' && socials.twitter !== undefined &&
                    <a href={socials.twitter} target="_blank" rel="noopener noreferrer">
                        <img src={Twitter} alt=""/>
                    </a>}
                {socials.youtube !== '' && socials.youtube !== undefined &&
                    <a href={socials.youtube} target="_blank" rel="noopener noreferrer">
                        <img src={Youtube} alt=""/>
                    </a>}
                {socials.facebook !== '' && socials.facebook !== undefined &&
                    <a href={socials.facebook} target="_blank" rel="noopener noreferrer">
                        <img src={Facebook} alt=""/>
                    </a>}
            </div>
        </div>
    );
}