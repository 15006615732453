import React, {useState} from 'react';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import InformationButton from "../elements/InformationButton";
import currentDateTime from "../../utils/currentDateTime";
import "../../assets/scss/_token-creation.scss"

export default function TokenConstructor({
                                             setVestingCount,
                                             formValues,
                                             setFormValues,
                                             setEncodedFormValues,
                                             ownerTokens,
                                             handleChangeTicker,
                                             handleChange,
                                             handleChangeTokenName,
                                             handleChangeMintable,
                                             handleChangeBurnable,
                                             handleChangePausable,
                                             handleSetOwnerTokens,
                                             setFundraisingStep,
                                             vestingCount,
                                             existingToken
                                         }) {
    const [tokenVesting, setTokenVesting] = useState(false);
    let actualDateTime = currentDateTime();



    const handleChangeTokenVesting = () => {
        setTokenVesting(!tokenVesting);
        if (!tokenVesting){
            setVestingCount(1);
        }
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);

        let newEncodedFormValues = [...formValues];
        newEncodedFormValues.splice(i, 1);
        setEncodedFormValues(newEncodedFormValues);
        const amnt = vestingCount - 1;
        setVestingCount(amnt);

    }

    let addFormFields = () => {
        setFormValues([...formValues, { label: "", saleStart: new Date(), durationPeriod: 0, durationPeriodOption:"day", releasePeriod : 0, releasePeriodOption: "day", lockupPeriod: 0, lockupPeriodOption: "day", tge: 0, tokenAmount: 0 }])
        const amnt = vestingCount + 1;
        setVestingCount(amnt);
    }
    return (
        <div className='token-creation'>
            <div className='-title'>Token constructor</div>
            { !existingToken &&
                <form >

                    <input
                        type="text"
                        name="name"
                        className="-input"
                        placeholder="Token name"
                        onChange={handleChangeTokenName}
                    />
                    <input
                        type="text"
                        name="ticker"
                        className="-input"
                        placeholder="Token ticker"
                        onChange={handleChangeTicker}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Switch />} onClick={handleChangeBurnable} label="Burnable" />
                        <FormControlLabel control={<Switch />} onClick={handleChangeMintable} label="Mintable" />
                        <FormControlLabel control={<Switch />} onClick={handleChangePausable} label="Pausable" />
                    </FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Vesting" onChange={handleChangeTokenVesting} />
                    {tokenVesting &&
                        <form>
                            {formValues.map((element, index) => (
                                <div key={index}>
                                    <div className='-text plan'>Vesting plan</div>
                                    <div className='-text'>Label</div>
                                    <div className='text'>
                                        <input
                                            type="text"
                                            name="label"
                                            className="-input short"
                                            placeholder=""
                                            onChange={e => handleChange(index, e)}
                                            value={element.label}
                                        />
                                    </div>
                                    <div className='-text'>Vesting start</div>
                                    <input
                                        type="datetime-local"
                                        name="saleStart"
                                        className="-input short"
                                        min={actualDateTime}
                                        value={element.saleStart}
                                        onChange={e => handleChange(index, e)}
                                    />
                                    <div className='-text'>Vesting period <InformationButton text='How long will vesting last'/></div>
                                    <div className='-input-grid'>
                                        <input
                                            type="number"
                                            min="1"
                                            max="31"
                                            name="durationPeriod"
                                            className="-input short"
                                            placeholder=""
                                            onChange={e => handleChange(index, e)}
                                            value={element.durationPeriod}
                                        />
                                        <select className='-input select' name="durationPeriodOption" onChange={e => handleChange(index, e)} value={element.durationPeriodOption}>
                                            <option value="day">day</option>
                                            <option value="week">week</option>
                                            <option value="month">month</option>
                                            <option value="quarter">quarter</option>
                                        </select>
                                    </div>
                                    <div className='-text'>Lockup period <InformationButton text='For how much time tokens will be locked'/></div>
                                    <div className='-input-grid'>
                                        <input
                                            name="lockupPeriod"
                                            className="-input short"
                                            placeholder=""
                                            onChange={e => handleChange(index, e)}
                                            value={element.lockupPeriod}
                                        />
                                        <select className='-input select' name="lockupPeriodOption" onChange={e => handleChange(index, e)} value={element.lockupPeriodOption}>
                                            <option value="day">day</option>
                                            <option value="week">week</option>
                                            <option value="month">month</option>
                                            <option value="quarter">quarter</option>
                                        </select>
                                    </div>
                                    <div className='-text'>Release period <InformationButton text='How often in vesting period tokens will be available for claim. If period is 0, tokens are distributed linearly'/></div>
                                    <div className='-input-grid'>
                                        <input
                                            type="number"
                                            min="1"
                                            max="31"
                                            name="releasePeriod"
                                            className="-input short"
                                            placeholder=""
                                            onChange={e => handleChange(index, e)}
                                            value={element.releasePeriod}
                                        />
                                        <select className='-input select' name="releasePeriodOption" onChange={e => handleChange(index, e)} value={element.releasePeriodOption}>
                                            <option value="day">day</option>
                                            <option value="week">week</option>
                                            <option value="month">month</option>
                                            <option value="month">month</option>
                                            <option value="quarter">quarter</option>
                                        </select>
                                    </div>
                                    <div className='-text'>TGE % <InformationButton text='How much tokens will be available right away to claim when vesting starts'/></div>
                                    <input className='-input short'
                                           type="number"
                                           value={element.tge}
                                           data-mask='0%'
                                           placeholder='percent'
                                           name="tge"
                                           onChange={e => handleChange(index, e)}
                                    />

                                    <div className='-text'>Total token allocated</div>
                                    <input className='-input short'
                                           type="number"
                                           value={element.tokenAmount}
                                           name="tokenAmount"
                                           onChange={e => handleChange(index, e)}
                                    />

                                    {
                                        index ?
                                            <div className='-right-block'>
                                                <button type="button"  className="-add-input remove" onClick={() => removeFormFields(index)}>Remove</button>
                                            </div>
                                            : null
                                    }
                                </div>
                            ))}
                            <div className='-right-block'>
                                <div className="button-section">
                                    <button className="-add-input" type="button" onClick={() => addFormFields()}>Add new vesting plan</button>
                                </div>
                            </div>
                        </form>
                    }
                </form>
            }
            <div className='-text'>Additionally minted owner tokens <InformationButton text='How much tokens will be minted additionally and held by owner. These tokens can then be distributed in other contracts, or send to other addresses, etc.'/></div>
            <input
                type="number"
                name="ownerTokens"
                className="-input"
                placeholder="Owner tokens"
                onChange={handleSetOwnerTokens}
                value={ownerTokens}
            />
            <div className="-fund-navigation">
                <button
                    className="-button"
                    onClick={() => setFundraisingStep(0)}
                >
                    Back
                </button>
                <button
                    className="-button"
                    onClick={() => setFundraisingStep(2)}
                >
                    Next
                </button>
            </div>
        </div>
    );
}