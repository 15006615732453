import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import "../../assets/scss/_modal.scss"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useAccount, useNetwork, useSwitchNetwork} from "wagmi";
import {toast} from "react-toastify";
import Spinner from "../Spinner";
import * as ethers from "ethers";
import {updateListingTierInDb} from "../../controllers/ListingController";
import {approveAmount, getAllowance} from "../../ethers/ERC20Contract";
import buyPackage, {getChainlinkDataFeedLatestAnswer} from "../../ethers/ForcefiPackage";
import erc20InvestmentTokens from "../../constants/ERC20InvestmentTokens";

const customStyles = {
    content: {
        width: 600,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0px',
        marginRight: '-50%',
        border: '2px solid #000',
        transform: 'translate(-50%, -50%)',
    },
};

const steps = [
    'Connecting to Ethereum',
    'Approving funds',
    'Upgrade package',
];
export default function UpdatePackage(props) {
    const [stepper, setStepper]= useState(0);
    const { chain } = useNetwork()
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const { address } = useAccount();

    const[isLoading, setIsLoading] = useState(true);

    const provider = window.ethereum ? new ethers.BrowserProvider(window.ethereum) : null;

    const { switchNetwork } =
        useSwitchNetwork()

    useEffect(() => {
        if (props.show){
            handleProceedUpdatePackage()
        }
    },[props.show])

    const handleProceedUpdatePackage = async () => {
        const chainlinkPrice = await getChainlinkDataFeedLatestAnswer(process.env.REACT_APP_FORCEFI_PACKAGE_INVESTMENT_TOKEN);
        const network = await provider.getNetwork()
        console.log('CHAIN ID ' + network.chainId)
        // const currentTokenAllowance = await getAllowance(erc20InvestmentTokens[network.chainId]["USDT"], address, process.env.REACT_APP_FORCEFI_PACKAGE_ADDRESS);
        const currentTokenAllowance = await getAllowance("0xC09291B33A6E7ba4D7c293D58a6683C1f9F2946C", address, process.env.REACT_APP_FORCEFI_PACKAGE_ADDRESS);
        let tokensToApprove;
        if (props.packageName === "Explorer"){
            tokensToApprove = 750;
        } else if (props.packageName === "Accelerator"){
            tokensToApprove = 2000;
        }

        const nr = (tokensToApprove * Number(chainlinkPrice)).toLocaleString();
        const normalizedTokenAmount = nr.replace(/,/g, '')
        if (currentTokenAllowance > tokensToApprove * Number(chainlinkPrice)){
            await buyPackage(props.companyName, props.packageName, erc20InvestmentTokens.SEPOLIA["USDT"], "0x0000000000000000000000000000000000000000")
        } else {
            handleApprove(normalizedTokenAmount)
        }
    }

    const handleApprove = async (amountToApprove) => {
        setIsLoading(true)
        setStepper(1)
        await approveAmount(erc20InvestmentTokens.SEPOLIA["USDT"], "0x6b0A28D26F5e992409287B16A9232E9ade9E769B", amountToApprove)
            .then( async () => {
                setStepper(2)
                const tier = await buyPackage(props.companyName, props.packageName, erc20InvestmentTokens.SEPOLIA["USDT"], "0x0000000000000000000000000000000000000000");
                await updateListingTier(tier, props.companyName);
            })
    }

    async function updateListingTier(listingTier, companyName) {
        await updateListingTierInDb(listingTier, companyName)
            .then(response => {
                setIsLoading(false);
                toast.success("Successfully upgraded package");
                props.close();
            })
    }

    return (
        <div>
            <Modal
                isOpen={props.show}
                onRequestClose={props.close}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='modal-content'>
                    <Stepper activeStep={stepper} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {/*{*/}
                    {/*    !isLoading &&*/}
                    {/*    <div className='center'>*/}
                    {/*        <button className='-button' onClick={handleUpgradePackage}>Upgrade package</button>*/}
                    {/*    </div>*/}
                    {/*}*/}

                </div>
                {isLoading && <Spinner text=''/>}
            </Modal>
        </div>
    );
}
