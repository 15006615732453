import React from 'react';
import "../assets/scss/_partners.scss"
import Astronaut from "../assets/images/Astronaut.png"

import {Button} from "antd/lib";

export default function Partners(props) {
    return (
        <>
        <div className="partners-img">
            <div className="main-container">
                Forcefi Leader Alliance
            </div>
        </div>
        <div className="main-container">
            <div className="partners-page">
                <div className="-text">
                    Forcefi has formed an alliance that brings together ecosystem leaders and Web3 organizations that want to support early-stage projects. The program seeks to create mutually beneficial relationships, foster collaboration, and help partners reach their business objectives.
                </div>
                <Button type="primary" style={{ width: '145px' }}>Join the Alliance!</Button>

                <div className="-title">
                    Who are we looking for?
                </div>
                <ol>
                    <li className="-text list">Industry experts / KOLs</li>
                    <li className="-text list">VCs / Web3 investment funds.</li>
                    <li className="-text list">Incubators and accelerators</li>
                    <li className="-text list">EVM compatible L1s and L2s</li>
                </ol>
                <div className="-title">
                    What do we offer?
                </div>
                <div className="-text">
                    The Forcefi Alliance offers tailored support and networking opportunities based on our partners’ blockchain domain and business goals.
                </div>
                <div className="-block">
                    <div className="-title">
                        Industry experts / KOLs
                    </div>
                    <div className="-title s">
                        Become a curator on our platform
                    </div>
                    <div className="-text">
                        Join nearly 200 industry experts and curate projects based on your expertise. Take advantage of our matchmaking features to only work with projects that you can confidently help. Join biweekly feedback calls with our projects to showcase your expertise.
                    </div>
                    <div className="-title s">
                        New revenue stream & project deal flow
                    </div>
                    <div className="-text">
                        Apply to work with projects on our platform and let them find you in our database. Curators get paid by both the project and our platform based on their performance. Read more here.
                    </div>
                    <div className="-title s">
                        Warm intros to relevant projects
                    </div>
                    <div className="-text">
                        After onboarding a project and understanding their pain points we make introductions to relevant curators. There is nothing better than word-of-mouth when it comes to making deals.
                    </div>
                    <div className="-title s">
                        Access to private TG group for curators
                    </div>
                    <div className="-text">
                        Access an exclusive Telegram group with our curators and build a stronger network. Join occasional social calls organized by Forcefi and get to know fellow curators on a personal level.
                    </div>
                </div>
                <div className="-block">
                    <div className="-title">
                        VCs / Web3 funds
                    </div>
                    <div className="-title s">
                        Warm intros to early-stage projects
                    </div>
                    <div className="-text">
                        After joining our accelerator tier, early-stage projects get warm intros to relevant VCs. We act as a filter to ensure that your team maximizes time efficiency.
                    </div>
                    <div className="-title s">
                        Let our curators do the research for you
                    </div>
                    <div className="-text">
                        The potential of a project is directly relevant to the curators supporting it. Check their on-chain reputation and ask for feedback to get a better idea of the projects you are interested in.
                    </div>
                    <div className="-title s">
                        Access to demo days & exclusive events
                    </div>
                    <div className="-text">
                        Participate in occasional events where higher-tier members pitch their projects. Join feedback calls and get to know the teams behind the projects.
                    </div>
                    <div className="-title s">
                        Access to private TG group for partners
                    </div>
                    <div className="-text">
                        Get exclusive access to our Alliance TG group to explore growth opportunities, meet cool Web3 folk, and get intros when they are most needed.
                    </div>
                    <div className="-title s">
                        Participate in seed-stage token sales
                    </div>
                    <div className="-text">
                        Take part in seed and private sales conducted on Forcefi by listed projects. Token sales are highly customizable allowing the process to follow the requirements of your contract.
                    </div>
                </div>

                <div className="-block">
                    <div className="-title">
                        Incubators, accelerators, & startup programs
                    </div>
                    <div className="-title s">
                        Mentor and support early-stage projects
                    </div>
                    <div className="-text">
                        Participate in biweekly Q&A calls and host webinars to support early stage projects looking for assistance. Distribute educational material through our email list.
                    </div>

                    <div className="-title s">
                        Collaborative marketing initiatives
                    </div>
                    <div className="-text">
                        Launch collaborative marketing campaigns with other Alliance members to spread awareness and increase your number of applications.
                    </div>

                    <div className="-title s">
                        Warm intros to relevant projects
                    </div>
                    <div className="-text">
                        Following the onboarding of new projects we make warm introductions to partners that are most relevant to their business objectives.
                    </div>

                    <div className="-title s">
                        Access to private TG group for partners
                    </div>
                    <div className="-text">
                        Get exclusive access to our Alliance TG group to explore growth opportunities, meet cool Web3 folk, and get intros when they are most needed.
                    </div>

                </div>

                <div className="-block disable-border">
                    <div className="-title">
                        EVM compatible L1s and L2s
                    </div>
                    <div className="-title s">
                        Ecosystem growth opportunities
                    </div>
                    <div className="-text">
                        We help ecosystem-specific dapps to launch and collaborate with key players by granting permissionless access to otherwise costly token launch functions. Teams get access to a growing community that follows and supports their project, growing the userbase of the ecosystem as a result.
                    </div>
                    <div className="-title s">
                        Ecosystem specific/agnostic Marketing
                    </div>
                    <div className="-text">
                        Participate in collaborative marketing initiatives, including social media campaigns, webinars, industry events, and credential data network campaigns.
                    </div>

                    <div className="-title s">
                        Alleviate BD burnouts
                    </div>
                    <div className="-text">
                        Simplify your BD efforts by letting us screen requests and build groups between ecosystem projects and relevant people in your team.
                    </div>

                    <div className="-title s">
                        Custom support
                    </div>
                    <div className="-text">
                        We help you reach business objectives through custom frameworks that streamline time-consuming activities and grow your TVL.
                    </div>
                    <div className="-title s">
                        Improve reputation & transparency
                    </div>
                    <div className="-text">
                        Thanks to our platform’s infrastructure, Forcefi-listed projects are held accountable for the way they distribute funds, building more trust with their community and ecosystem. This, in turn, improves the quality of projects on the ecosystem.
                    </div>

                </div>
                <div className="-mail-block">
                    Do you have any questions? Reach out to dimitris@forcefi.io
                    <img className="-img" src={Astronaut} alt=""/>
                </div>

            </div>
        </div>
        </>
    );
}