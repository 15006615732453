import React, {useState} from 'react';
// import axios from "axios";
import { Tooltip } from 'antd';
import Skills from "./user/Skills";
import Avatar from "./user/Avatar";
import Description from "./user/Description";

export default function CuratorsCard ({curator, key, hasCurratorAccess, handleMatchCurator= () =>console.log(""), matchEnable=true }) {
    // const [isConnected, setIsConnected] = useState(false);
    // const matchCurator = (walletAddress) => {
    //     const newState = !isConnected;
    //     setIsConnected(newState);
    //     const request = newState ? '/api/matchCurator' : '/api/removeMatchCurator';
    //     axios.post(process.env.REACT_APP_API_ENDPOINT + request, {
    //         mainWalletAddress: companyAddress.mainWalletAddress,
    //         walletAddress: walletAddress
    //     })
    //         .then((response) => console.log(response.data) );
    // }
    return (
        <div className='curator'>
            {hasCurratorAccess && matchEnable &&
                <Tooltip title="Matching allows to start communicating with telegram" placement="right">
                    <div className="-match-btn" onClick={() => handleMatchCurator(curator.name, curator.walletAddress)}>Match</div>
                </Tooltip>
            }
            <div className="-user">
                <Avatar name={curator.name} img={curator.img} socials={curator} />
            </div>
            <Description title='Description' text={curator.aboutme} />
            <div className="-expertise-container">
                <Skills roles={curator.roles} />
            </div>
        </div>
    );
}
